/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_Requests_AddVendorLocationRequest } from '../models/Vendor_Models_Requests_AddVendorLocationRequest';
import type { Vendor_Models_Requests_UpdateVendorLocationRequest } from '../models/Vendor_Models_Requests_UpdateVendorLocationRequest';
import type { Vendor_Models_VendorLocationDto } from '../models/Vendor_Models_VendorLocationDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorLocationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor locations
     * Display vendor locations
     * @returns Vendor_Models_VendorLocationDto The request completed successfully
     * @throws ApiError
     */
    public getApiV11VendorsVendorLocations({
        vendorId,
    }: {
        /**
         * The numeric ID for the location
         */
        vendorId: number,
    }): Observable<Array<Vendor_Models_VendorLocationDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/vendors/{vendorId}/vendor-locations',
            path: {
                'vendorId': vendorId,
            },
            errors: {
                400: `Bad request`,
                403: `Authorization failed`,
                404: `The specified Vendor could not be found`,
            },
        });
    }
    /**
     * Add vendor location
     * Add vendor location
     * @returns any The location was added successfully
     * @throws ApiError
     */
    public postApiV11VendorsVendorLocations({
        requestBody,
    }: {
        requestBody?: Vendor_Models_Requests_AddVendorLocationRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/vendors/vendor-locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                403: `Authorization failed`,
                404: `The specified Vendor could not be found`,
                409: `A primary location for the Vendor already exists`,
            },
        });
    }
    /**
     * Update vendor location
     * Update vendor location
     * @returns any The location was updated successfully
     * @throws ApiError
     */
    public putApiV11VendorsVendorLocations({
        requestBody,
    }: {
        requestBody?: Vendor_Models_Requests_UpdateVendorLocationRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/vendors/vendor-locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                403: `Authorization failed`,
                404: `The specified Vendor or location could not be found`,
                409: `A primary location for the Vendor already exists`,
            },
        });
    }
    /**
     * Activate vendor location
     * Activate vendor location
     * @returns any The location set to active successfully
     * @throws ApiError
     */
    public postApiV11VendorsActivation({
        vendorLocationId,
        requestBody,
    }: {
        vendorLocationId: number,
        requestBody?: boolean,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/vendors/{vendorLocationId}/activation',
            path: {
                'vendorLocationId': vendorLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Authorization failed`,
                404: `The specified Vendor or location could not be found`,
            },
        });
    }
}
