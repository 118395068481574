/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_UpdateVendorInvoiceDocumentRequest } from '../models/Invoice_Models_Requests_UpdateVendorInvoiceDocumentRequest';
import type { Invoice_Models_Requests_VendorInvoiceCopyDocumentsRequest } from '../models/Invoice_Models_Requests_VendorInvoiceCopyDocumentsRequest';
import type { Invoice_Models_VendorInvoiceDocumentDto } from '../models/Invoice_Models_VendorInvoiceDocumentDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice documents
     * Display vendor invoice documents
     * @returns Invoice_Models_VendorInvoiceDocumentDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesDocuments({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_VendorInvoiceDocumentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/{vendorInvoiceId}/documents',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Add vendor invoice documents
     * Add vendor invoice documents
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesDocuments({
        vendorInvoiceId,
        formData,
    }: {
        vendorInvoiceId: number,
        formData: {
            VendorInvoiceDocumentTypeId?: number;
            Document?: Blob;
            CustomLogMessage?: string;
            HasSignature?: string;
            SignatureDate?: string;
            SubmittedName?: string;
        },
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/documents',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update document
     * Update document
     * @returns any Success
     * @throws ApiError
     */
    public putApiV1InvoicesDocuments({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_UpdateVendorInvoiceDocumentRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/documents',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Download document
     * Download document
     * @returns any OK
     * @throws ApiError
     */
    public getApiV1InvoicesDocumentsDownload({
        documentId,
    }: {
        documentId: number,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/documents/{documentId}/download',
            path: {
                'documentId': documentId,
            },
        });
    }
    /**
     * Add vendor invoice redacted documents
     * Add vendor invoice redacted documents
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesRedactedDocuments({
        vendorInvoiceUuid,
        formData,
    }: {
        vendorInvoiceUuid: string,
        formData: {
            VendorInvoiceDocumenUuid?: string;
            Document?: Blob;
        },
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceUuid}/redacted-documents',
            path: {
                'vendorInvoiceUuid': vendorInvoiceUuid,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Add vendor invoice documents
     * Add vendor invoice documents
     * @returns any Success
     * @throws ApiError
     */
    public deleteApiV1InvoicesDocuments({
        documentId,
        vendorInvoiceId,
    }: {
        documentId: number,
        vendorInvoiceId: number,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/invoices/{vendorInvoiceId}/documents/{documentId}',
            path: {
                'documentId': documentId,
                'vendorInvoiceId': vendorInvoiceId,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update document Type
     * Update document Type
     * @returns any Success
     * @throws ApiError
     */
    public patchApiV1InvoicesDocumentsDocumentType({
        vendorInvoiceId,
        documentId,
        documentTypeId,
    }: {
        vendorInvoiceId: number,
        documentId: number,
        documentTypeId: number,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/invoices/{vendorInvoiceId}/documents/{documentId}/document-type/{documentTypeId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
                'documentId': documentId,
                'documentTypeId': documentTypeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Copies documents from an origin invoice to a destination invoice
     * Copies documents from an origin invoice to a destination invoice
     * @returns any Success
     * @throws ApiError
     */
    public postApiV1InvoicesDocumentsCopy({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceCopyDocumentsRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/documents/copy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
