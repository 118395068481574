/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_Models_Request_TenantSettingsRequest } from '../models/Identity_Application_Models_Request_TenantSettingsRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TenantSettingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display tenant Integration settings
     * Display tenant Integration settings
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1TenantsIntegrationSettings({
        tenantGuid,
    }: {
        tenantGuid: string,
    }): Observable<Record<string, Record<string, string>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/tenants/{tenantGuid}/integration/settings',
            path: {
                'tenantGuid': tenantGuid,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Edit Tenant integration settings
     * Edit tenant integration settings
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1TenantsIntegrationSettings({
        tenantGuid,
        requestBody,
    }: {
        tenantGuid: string,
        requestBody?: Identity_Application_Models_Request_TenantSettingsRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/tenants/{tenantGuid}/integration/settings',
            path: {
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Display tenant webhook settings
     * Display tenant webhook settings
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1TenantsWebhooksSettings({
        tenantGuid,
    }: {
        tenantGuid: string,
    }): Observable<Record<string, Record<string, string>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/tenants/{tenantGuid}/webhooks/settings',
            path: {
                'tenantGuid': tenantGuid,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Edit Tenant webhooks settings
     * Edit tenant webhooks settings
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1TenantsWebhooksSettings({
        tenantGuid,
        requestBody,
    }: {
        tenantGuid: string,
        requestBody?: Identity_Application_Models_Request_TenantSettingsRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/tenants/{tenantGuid}/webhooks/settings',
            path: {
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
}
