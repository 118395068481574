/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Order_Models_OrderDto } from '../models/Order_Models_OrderDto';
import type { Order_Models_Requests_OrderRequest } from '../models/Order_Models_Requests_OrderRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @deprecated
     * Display order
     * Display order
     * @returns Order_Models_OrderDto Success
     * @throws ApiError
     */
    public getApiV1Orders({
        orderId,
    }: {
        orderId: number,
    }): Observable<Order_Models_OrderDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display matched invoices of an order
     * Display matched invoices of an order
     * @returns Order_Models_OrderDto Success
     * @throws ApiError
     */
    public getApiV1OrdersInvoices({
        orderId,
    }: {
        orderId: number,
    }): Observable<Order_Models_OrderDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/{orderId}/invoices',
            path: {
                'orderId': orderId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Get matched approved invoices of an order based on order External Id
     * Get matched invoices of an order based on order Uuid
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1OrdersApprovedInvoices({
        orderExternalId,
    }: {
        orderExternalId: string,
    }): Observable<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/{orderExternalId}/approved-invoices',
            path: {
                'orderExternalId': orderExternalId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Add order
     * Add order
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1OrdersCreate({
        requestBody,
    }: {
        requestBody?: Order_Models_Requests_OrderRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/orders/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
