/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Dispute_Api_DTOs_DisputeReasonResponse } from '../models/Dispute_Api_DTOs_DisputeReasonResponse';
import type { Navix_Services_Sdk_Communication } from '../models/Navix_Services_Sdk_Communication';
import type { Navix_Services_Sdk_Dispute } from '../models/Navix_Services_Sdk_Dispute';
import type { Navix_Services_Sdk_DisputeModificationDTO } from '../models/Navix_Services_Sdk_DisputeModificationDTO';
import type { Navix_Services_Sdk_DisputeReason } from '../models/Navix_Services_Sdk_DisputeReason';
import type { Navix_Services_Sdk_DisputeReasonGroup } from '../models/Navix_Services_Sdk_DisputeReasonGroup';
import type { Navix_Services_Sdk_DisputeReasonGroupCreationDTO } from '../models/Navix_Services_Sdk_DisputeReasonGroupCreationDTO';
import type { Navix_Services_Sdk_DisputeReasonGroupModificationDTO } from '../models/Navix_Services_Sdk_DisputeReasonGroupModificationDTO';
import type { Navix_Services_Sdk_DisputeReasonModificationDTO } from '../models/Navix_Services_Sdk_DisputeReasonModificationDTO';
import type { Navix_Services_Sdk_DisputeReasonsCreationDTO } from '../models/Navix_Services_Sdk_DisputeReasonsCreationDTO';
import type { Navix_Services_Sdk_EmailTemplate } from '../models/Navix_Services_Sdk_EmailTemplate';
import type { Navix_Services_Sdk_EmailTemplateCreationDTO } from '../models/Navix_Services_Sdk_EmailTemplateCreationDTO';
import type { Navix_Services_Sdk_EmailTemplateModificationDTO } from '../models/Navix_Services_Sdk_EmailTemplateModificationDTO';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class DisputeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Gets a list of dispute reason groups for a specific tenant.
     * Gets a list of dispute reason groups for a specific tenant.
     * @returns Navix_Services_Sdk_DisputeReasonGroup Success
     * @throws ApiError
     */
    public getApiV2DisputeReasonGroups(): Observable<Array<Navix_Services_Sdk_DisputeReasonGroup>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/dispute-reason-groups',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Creates new dispute reason groups for a specific tenant
     * Creates new dispute reason groups for a specific tenant
     * @returns Navix_Services_Sdk_DisputeReasonGroup Created
     * @throws ApiError
     */
    public postApiV2DisputeReasonGroupsMultiple({
        requestBody,
    }: {
        requestBody?: Array<Navix_Services_Sdk_DisputeReasonGroupCreationDTO>,
    }): Observable<Navix_Services_Sdk_DisputeReasonGroup> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/dispute-reason-groups/multiple',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Updates an existing dispute reason group.
     * Updates an existing dispute reason group.
     * @returns Navix_Services_Sdk_DisputeReasonGroup Success
     * @throws ApiError
     */
    public putApiV2DisputeReasonGroups({
        disputeReasonGroupUuid,
        requestBody,
    }: {
        disputeReasonGroupUuid: string,
        requestBody?: Navix_Services_Sdk_DisputeReasonGroupModificationDTO,
    }): Observable<Navix_Services_Sdk_DisputeReasonGroup> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/dispute-reason-groups/{disputeReasonGroupUuid}',
            path: {
                'disputeReasonGroupUuid': disputeReasonGroupUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Gets a list of dispute reasons for a specific tenant
     * Gets a list of dispute reasons for a specific tenant
     * @returns Dispute_Api_DTOs_DisputeReasonResponse Success
     * @throws ApiError
     */
    public getApiV2DisputeReasons(): Observable<Array<Dispute_Api_DTOs_DisputeReasonResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/dispute-reasons',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Creates a list dispute reasons for a specific tenant
     * Creates a list of dispute reasons for a specific tenant
     * @returns any Created
     * @throws ApiError
     */
    public postApiV2DisputeReasons({
        requestBody,
    }: {
        requestBody?: Navix_Services_Sdk_DisputeReasonsCreationDTO,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/dispute-reasons',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Updates an existing dispute reason.
     * Updates an existing dispute reason.
     * @returns Navix_Services_Sdk_DisputeReason Success
     * @throws ApiError
     */
    public putApiV2DisputeReasons({
        disputeReasonUuid,
        requestBody,
    }: {
        disputeReasonUuid: string,
        requestBody?: Navix_Services_Sdk_DisputeReasonModificationDTO,
    }): Observable<Navix_Services_Sdk_DisputeReason> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/dispute-reasons/{disputeReasonUuid}',
            path: {
                'disputeReasonUuid': disputeReasonUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Download the dispute's attachment
     * Download the dispute's attachment
     * @returns any OK
     * @throws ApiError
     */
    public postApiV2AttachmentsDownload({
        requestBody,
    }: {
        requestBody?: string,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/attachments/download',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Display dispute list
     * Display dispute
     * @returns Navix_Services_Sdk_Dispute Success
     * @throws ApiError
     */
    public getApiV2InvoicesDisputes({
        invoiceUuid,
    }: {
        invoiceUuid: string,
    }): Observable<Array<Navix_Services_Sdk_Dispute>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/invoices/{invoiceUuid}/disputes',
            path: {
                'invoiceUuid': invoiceUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Create a new dispute
     * Create a new dispute
     * @returns void
     * @throws ApiError
     */
    public postApiV2InvoicesDisputes({
        invoiceUuid,
        requestBody,
    }: {
        invoiceUuid: string,
        requestBody?: any,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/{invoiceUuid}/disputes',
            path: {
                'invoiceUuid': invoiceUuid,
            },
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Update dispute reasons
     * Update dispute reasons
     * @returns void
     * @throws ApiError
     */
    public postApiV2InvoicesDisputesReasons({
        invoiceUuid,
        disputeUuid,
        requestBody,
    }: {
        invoiceUuid: string,
        disputeUuid: string,
        requestBody?: Array<string>,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/{invoiceUuid}/disputes/{disputeUuid}/reasons',
            path: {
                'invoiceUuid': invoiceUuid,
                'disputeUuid': disputeUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display list of dispute communications
     * Display dispute communications
     * @returns Navix_Services_Sdk_Communication Success
     * @throws ApiError
     */
    public getApiV2InvoicesDisputesCommunications({
        invoiceUuid,
        disputeUuid,
    }: {
        invoiceUuid: string,
        disputeUuid: string,
    }): Observable<Array<Navix_Services_Sdk_Communication>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/invoices/{invoiceUuid}/disputes/{disputeUuid}/communications',
            path: {
                'invoiceUuid': invoiceUuid,
                'disputeUuid': disputeUuid,
            },
        });
    }
    /**
     * Create a new communication
     * Create a new communication
     * @returns void
     * @throws ApiError
     */
    public postApiV2InvoicesDisputesCommunications({
        invoiceUuid,
        disputeUuid,
        formData,
    }: {
        invoiceUuid: string,
        disputeUuid: string,
        formData: {
            To?: string;
            Cc?: string;
            Text?: string;
            Subject?: string;
            AttachmentUrls?: string;
            AttachmentFiles?: string;
        },
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/{invoiceUuid}/disputes/{disputeUuid}/communications',
            path: {
                'invoiceUuid': invoiceUuid,
                'disputeUuid': disputeUuid,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display dispute communication
     * Display dispute communication
     * @returns Navix_Services_Sdk_Communication Success
     * @throws ApiError
     */
    public getApiV2InvoicesDisputesCommunications1({
        invoiceUuid,
        disputeUuid,
        communicationUuid,
    }: {
        invoiceUuid: string,
        disputeUuid: string,
        communicationUuid: string,
    }): Observable<Navix_Services_Sdk_Communication> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/invoices/{invoiceUuid}/disputes/{disputeUuid}/communications/{communicationUuid}',
            path: {
                'invoiceUuid': invoiceUuid,
                'disputeUuid': disputeUuid,
                'communicationUuid': communicationUuid,
            },
        });
    }
    /**
     * Updates dispute
     * Updates dispute
     * @returns void
     * @throws ApiError
     */
    public putApiV2InvoicesDisputes({
        invoiceUuid,
        disputeUuid,
        requestBody,
    }: {
        invoiceUuid: string,
        disputeUuid: string,
        requestBody?: Navix_Services_Sdk_DisputeModificationDTO,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/invoices/{invoiceUuid}/disputes/{disputeUuid}',
            path: {
                'invoiceUuid': invoiceUuid,
                'disputeUuid': disputeUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display single dispute
     * Display dispute
     * @returns Navix_Services_Sdk_Dispute Success
     * @throws ApiError
     */
    public getApiV2InvoicesDisputes1({
        invoiceUuid,
        disputeUuid,
    }: {
        invoiceUuid: string,
        disputeUuid: string,
    }): Observable<Navix_Services_Sdk_Dispute> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/invoices/{invoiceUuid}/disputes/{disputeUuid}',
            path: {
                'invoiceUuid': invoiceUuid,
                'disputeUuid': disputeUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Gets a list of email templates for a specific tenant.
     * Gets a list of email templates for a specific tenant.
     * @returns Navix_Services_Sdk_EmailTemplate Success
     * @throws ApiError
     */
    public getApiV2EmailTemplates(): Observable<Array<Navix_Services_Sdk_EmailTemplate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/email/templates',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Creates a new email template for a specific tenant
     * Creates a new email templates for a specific tenant
     * @returns Navix_Services_Sdk_EmailTemplate Created
     * @throws ApiError
     */
    public postApiV2EmailTemplates({
        requestBody,
    }: {
        requestBody?: Navix_Services_Sdk_EmailTemplateCreationDTO,
    }): Observable<Navix_Services_Sdk_EmailTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/email/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Updates an existing email template.
     * Updates an existing email template.
     * @returns Navix_Services_Sdk_EmailTemplate Success
     * @throws ApiError
     */
    public putApiV2EmailTemplates({
        emailTemplateUuid,
        requestBody,
    }: {
        emailTemplateUuid: string,
        requestBody?: Navix_Services_Sdk_EmailTemplateModificationDTO,
    }): Observable<Navix_Services_Sdk_EmailTemplate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/email/templates/{emailTemplateUuid}',
            path: {
                'emailTemplateUuid': emailTemplateUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Deletes an email template.
     * Deletes an email template.
     * @returns void
     * @throws ApiError
     */
    public deleteApiV2EmailTemplates({
        emailTemplateUuid,
    }: {
        emailTemplateUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/email/templates/{emailTemplateUuid}',
            path: {
                'emailTemplateUuid': emailTemplateUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
