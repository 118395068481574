/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_Models_Request_ReportRequest } from '../models/Identity_Application_Models_Request_ReportRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display all reports
     * Display all reports
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthReports(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/reports',
        });
    }
    /**
     * Add Report
     * Add Report in the system
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthReports({
        requestBody,
    }: {
        requestBody?: Identity_Application_Models_Request_ReportRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display reports for user assigned roles
     * Display reports for user assigned roles
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthUserReports(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/user-reports',
        });
    }
    /**
     * Update Report
     * Update Report in the system
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1AuthReports({
        reportId,
        requestBody,
    }: {
        reportId: number,
        requestBody?: Identity_Application_Models_Request_ReportRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Remove Report
     * Remove Report in the system
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1AuthReports({
        reportId,
    }: {
        reportId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/auth/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display Embedded report
     * Display Embedded report
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthReportsEmbedded({
        reportId,
    }: {
        reportId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/reports/embedded/{reportId}',
            path: {
                'reportId': reportId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
