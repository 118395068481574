/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_AutomationFlowDto } from '../models/Navix_Automation_Models_AutomationFlowDto';
import type { Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest } from '../models/Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest';
import type { Navix_Automation_Models_Requests_FlowEditorRequest } from '../models/Navix_Automation_Models_Requests_FlowEditorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationFlowsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display automation flows per tenant
     * Display automation flows per tenant
     * @returns Navix_Automation_Models_AutomationFlowDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlows({
        name,
        triggerType,
    }: {
        name?: string,
        triggerType?: string,
    }): Observable<Array<Navix_Automation_Models_AutomationFlowDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows',
            query: {
                'name': name,
                'triggerType': triggerType,
            },
        });
    }
    /**
     * Add automation flow
     * Add automation flow
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationFlows({
        requestBody,
    }: {
        requestBody?: Navix_Automation_Models_Requests_FlowEditorRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Display automation flow
     * Display automation flow
     * @returns Navix_Automation_Models_AutomationFlowDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlows1({
        flowUuid,
    }: {
        flowUuid: string,
    }): Observable<Navix_Automation_Models_AutomationFlowDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}',
            path: {
                'flowUuid': flowUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update automation flow
     * Update automation flow
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlows({
        flowUuid,
        requestBody,
    }: {
        flowUuid: string,
        requestBody?: Navix_Automation_Models_Requests_FlowEditorRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}',
            path: {
                'flowUuid': flowUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Delete automation flow
     * Delete automation flow
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationFlows({
        flowUuid,
    }: {
        flowUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/flows/{flowUuid}',
            path: {
                'flowUuid': flowUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Display a list of automation flows that depends on a given flow
     * Display a list of automation flows that depends on a given flow
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsDependents({
        flowUuid,
    }: {
        flowUuid: string,
    }): Observable<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/dependents',
            path: {
                'flowUuid': flowUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update execution orders of tenant's automation flows
     * Update execution orders of tenant's automation flows
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsUpdateExecutionOrder({
        flowUuid,
        requestBody,
    }: {
        flowUuid: string,
        requestBody?: Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/update-execution-order',
            path: {
                'flowUuid': flowUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Download a json file with the selected automation flow
     * Download a json file containing the selected flow and the flows that might been called inside it.
     * @returns any OK
     * @throws ApiError
     */
    public postApiV1AutomationFlowsExport({
        requestBody,
    }: {
        requestBody?: Array<string>,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/export',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Upload a json file with the automation flows to be imported.
     * Upload a json file containing the flows to be imported.
     * @returns void
     * @throws ApiError
     */
    public postApiV1AutomationFlowsImport({
        formData,
    }: {
        formData: Record<string, any>,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
