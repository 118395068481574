/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_VendorContactDto } from '../models/Vendor_Models_VendorContactDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorContactsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor contact by id
     * Display vendor contact by id
     * @returns Vendor_Models_VendorContactDto Success
     * @throws ApiError
     */
    public getApiV1VendorsVendorContacts({
        vendorContactId,
    }: {
        vendorContactId: number,
    }): Observable<Vendor_Models_VendorContactDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/vendors/vendor-contacts/{vendorContactId}',
            path: {
                'vendorContactId': vendorContactId,
            },
        });
    }
}
