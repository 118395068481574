/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceRequest } from '../models/Invoice_Models_Requests_VendorInvoiceRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add vendor invoice
     * Add vendor invoice
     * @returns string Created
     * @throws ApiError
     */
    public postApiV11InvoicesInvoices({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/invoices/invoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
