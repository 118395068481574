/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_Actions_AutomationActionDto } from '../models/Navix_Automation_Models_Actions_AutomationActionDto';
import type { Navix_Automation_Models_AutomationRuleDetailDto } from '../models/Navix_Automation_Models_AutomationRuleDetailDto';
import type { Navix_Automation_Models_AutomationRuleDto } from '../models/Navix_Automation_Models_AutomationRuleDto';
import type { Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest } from '../models/Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest';
import type { Navix_Automation_Models_RuleEditorRequest } from '../models/Navix_Automation_Models_RuleEditorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationRulesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display automation rules inside a flow
     * Display automation rules inside a flow
     * @returns Navix_Automation_Models_AutomationRuleDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRules({
        flowUuid,
    }: {
        flowUuid: string,
    }): Observable<Array<Navix_Automation_Models_AutomationRuleDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules',
            path: {
                'flowUuid': flowUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Add automation rule
     * Add automation rule
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationFlowsRules({
        flowUuid,
        requestBody,
    }: {
        flowUuid: string,
        requestBody?: Navix_Automation_Models_RuleEditorRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/{flowUuid}/rules',
            path: {
                'flowUuid': flowUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Display automation rule detail
     * Display automation rule detail
     * @returns Navix_Automation_Models_AutomationRuleDetailDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRules1({
        flowUuid,
        ruleUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
    }): Observable<Navix_Automation_Models_AutomationRuleDetailDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update automation rule
     * Update automation rule
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRules({
        flowUuid,
        ruleUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        requestBody?: Navix_Automation_Models_RuleEditorRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Delete automation rule
     * Delete automation rule
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationFlowsRules({
        flowUuid,
        ruleUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Display automation actions inside a rule
     * Display automation actions inside a rule
     * @returns Navix_Automation_Models_Actions_AutomationActionDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRulesActions({
        flowUuid,
        ruleUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
    }): Observable<Array<Navix_Automation_Models_Actions_AutomationActionDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/actions',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update execution orders of rules inside an automation flow
     * Update execution orders of rules inside an automation flow
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRulesUpdateExecutionOrder({
        flowUuid,
        ruleUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        requestBody?: Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/update-execution-order',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Update execution orders of actions inside an automation rule
     * Update execution orders of actions inside an automation rule
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRulesActionsUpdateExecutionOrder({
        flowUuid,
        ruleUuid,
        actionUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
        requestBody?: Navix_Automation_Models_Requests_ExecutionOrderUpdateRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/actions/{actionUuid}/update-execution-order',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
}
