/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Customer_Models_CustomerLocationDto } from '../models/Customer_Models_CustomerLocationDto';
import type { Customer_Models_Requests_CustomerLocationRequest } from '../models/Customer_Models_Requests_CustomerLocationRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomerLocationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer locations
     * Display customer locations
     * @returns Customer_Models_CustomerLocationDto Success
     * @throws ApiError
     */
    public getApiV11CustomersCustomerLocations({
        customerId,
    }: {
        customerId: number,
    }): Observable<Array<Customer_Models_CustomerLocationDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/customers/{customerId}/customer-locations',
            path: {
                'customerId': customerId,
            },
        });
    }
    /**
     * Add customer location
     * Add customer location
     * @returns string Created
     * @throws ApiError
     */
    public postApiV11CustomersCustomerLocations({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_CustomerLocationRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/customers/customer-locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Update customer location
     * Update custommer location
     * @returns string Success
     * @throws ApiError
     */
    public putApiV11CustomersCustomerLocations({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_CustomerLocationRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/customers/customer-locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Activate customer location
     * Activate customer location
     * @returns string Success
     * @throws ApiError
     */
    public postApiV11CustomersActivation({
        customerLocationId,
        requestBody,
    }: {
        customerLocationId: number,
        requestBody?: boolean,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/customers/{customerLocationId}/activation',
            path: {
                'customerLocationId': customerLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
