/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_VendorStatusTypeDto } from '../models/Vendor_Models_VendorStatusTypeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorStatusTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get vendor status types
     * Get vendor status types
     * @returns Vendor_Models_VendorStatusTypeDto Success
     * @throws ApiError
     */
    public getApiV1VendorsVendorStatusTypes(): Observable<Array<Vendor_Models_VendorStatusTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/vendors/vendor-status-types',
        });
    }
}
