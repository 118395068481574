/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_CustomerAdjustedChargeDto } from '../models/Invoice_Models_CustomerAdjustedChargeDto';
import type { Invoice_Models_CustomerAdjustedChargeDtoNew } from '../models/Invoice_Models_CustomerAdjustedChargeDtoNew';
import type { Invoice_Models_Requests_AddCustomerAdjustedChargeBatchRequest } from '../models/Invoice_Models_Requests_AddCustomerAdjustedChargeBatchRequest';
import type { Invoice_Models_Requests_AddCustomerAdjustedChargeRequest } from '../models/Invoice_Models_Requests_AddCustomerAdjustedChargeRequest';
import type { Invoice_Models_Requests_UpdateCustomerAdjustedChargeBatchRequest } from '../models/Invoice_Models_Requests_UpdateCustomerAdjustedChargeBatchRequest';
import type { Invoice_Models_Requests_UpdateCustomerAdjustedChargeRequest } from '../models/Invoice_Models_Requests_UpdateCustomerAdjustedChargeRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomerAdjustedChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer adjusted charges
     * Display customer adjusted charges
     * @returns Invoice_Models_CustomerAdjustedChargeDto Success
     * @throws ApiError
     */
    public customerAdjustedCharges({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_CustomerAdjustedChargeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/customer-adjusted-charges',
            query: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Add single customer adjusted charges
     * Add single customer adjusted charges
     * @returns string Success
     * @throws ApiError
     */
    public addCustomerAdjustedCharges({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_AddCustomerAdjustedChargeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/customer-adjusted-charges',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Update single customer adjusted charges
     * Update single  customer adjusted charges
     * @returns string Success
     * @throws ApiError
     */
    public updateCustomerAdjustedCharges({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_UpdateCustomerAdjustedChargeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/customer-adjusted-charges',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display customer adjusted charges
     * Display customer adjusted charges
     * @returns Invoice_Models_CustomerAdjustedChargeDtoNew Success
     * @throws ApiError
     */
    public customerAdjustedChargesNew({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_CustomerAdjustedChargeDtoNew>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/customer-adjusted-charges/{vendorInvoiceId}/new',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Add batch customer adjusted charges
     * Add batch customer adjusted charges
     * @returns string Success
     * @throws ApiError
     */
    public addBatchCustomerAdjustedCharges({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_AddCustomerAdjustedChargeBatchRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/customer-adjusted-charges/batch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Update batch customer adjusted charges
     * Update batch customer adjusted charges
     * @returns string Success
     * @throws ApiError
     */
    public updateBatchCustomerAdjustedCharges({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_UpdateCustomerAdjustedChargeBatchRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/customer-adjusted-charges/batch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
}
