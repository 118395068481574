/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Enums_InvoicesSortBy } from '../models/Invoice_Models_Enums_InvoicesSortBy';
import type { Invoice_Models_Enums_InvoiceState } from '../models/Invoice_Models_Enums_InvoiceState';
import type { Invoice_Models_Enums_SortOrder } from '../models/Invoice_Models_Enums_SortOrder';
import type { Invoice_Models_InvoiceOverviewPagedResponseDto } from '../models/Invoice_Models_InvoiceOverviewPagedResponseDto';
import type { Invoice_Models_Requests_VendorInvoiceDatesRequest } from '../models/Invoice_Models_Requests_VendorInvoiceDatesRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateCustomerRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateCustomerRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateFreightTermsRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateFreightTermsRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateVendorRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateVendorRequest';
import type { Invoice_Models_TenantInfoDto } from '../models/Invoice_Models_TenantInfoDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class InvoicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns the tenantId of the vendor invoice
     * Returns the tenantId of the vendor invoice
     * @returns Invoice_Models_TenantInfoDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesTenant({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Invoice_Models_TenantInfoDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/{vendorInvoiceId}/tenant',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Returns the tenantId of the vendor invoice
     * Returns the tenantId of the vendor invoice
     * @returns Invoice_Models_InvoiceOverviewPagedResponseDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesDashboardOverview({
        customerIds,
        vendorIds,
        exceptionCategoryIds,
        disputeReasonIds,
        invoiceFormatIds,
        vendorInvoiceStatusDescriptionIds,
        ownerUserUuids,
        shipmentNumber,
        orderNumber,
        invoiceNumber,
        searchBy,
        isUnowned,
        isMatched,
        hasNoExceptions,
        parentInvoiceId,
        isReadyToApprove,
        returnVendorNetCharge,
        divisions,
        invoiceStateId,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
    }: {
        customerIds?: Array<number>,
        vendorIds?: Array<number>,
        exceptionCategoryIds?: Array<number>,
        disputeReasonIds?: Array<number>,
        invoiceFormatIds?: Array<number>,
        vendorInvoiceStatusDescriptionIds?: Array<number>,
        ownerUserUuids?: Array<string>,
        shipmentNumber?: string,
        orderNumber?: string,
        invoiceNumber?: string,
        searchBy?: string,
        isUnowned?: boolean,
        isMatched?: boolean,
        hasNoExceptions?: boolean,
        parentInvoiceId?: number,
        isReadyToApprove?: boolean,
        returnVendorNetCharge?: boolean,
        divisions?: Array<string>,
        invoiceStateId?: Invoice_Models_Enums_InvoiceState,
        sortBy?: Invoice_Models_Enums_InvoicesSortBy,
        sortOrder?: Invoice_Models_Enums_SortOrder,
        pageNumber?: number,
        pageSize?: number,
    }): Observable<Invoice_Models_InvoiceOverviewPagedResponseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/dashboard-overview',
            query: {
                'CustomerIds': customerIds,
                'VendorIds': vendorIds,
                'ExceptionCategoryIds': exceptionCategoryIds,
                'DisputeReasonIds': disputeReasonIds,
                'InvoiceFormatIds': invoiceFormatIds,
                'VendorInvoiceStatusDescriptionIds': vendorInvoiceStatusDescriptionIds,
                'OwnerUserUuids': ownerUserUuids,
                'ShipmentNumber': shipmentNumber,
                'OrderNumber': orderNumber,
                'InvoiceNumber': invoiceNumber,
                'SearchBy': searchBy,
                'IsUnowned': isUnowned,
                'IsMatched': isMatched,
                'HasNoExceptions': hasNoExceptions,
                'ParentInvoiceId': parentInvoiceId,
                'IsReadyToApprove': isReadyToApprove,
                'ReturnVendorNetCharge': returnVendorNetCharge,
                'Divisions': divisions,
                'InvoiceStateId': invoiceStateId,
                'SortBy': sortBy,
                'SortOrder': sortOrder,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }
    /**
     * @deprecated
     * Returns the dashboard invoice list
     * Returns the dashboard invoice list
     * @returns any Success
     * @throws ApiError
     */
    public getApiV1InvoicesDashboard({
        options,
    }: {
        options?: any,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/dashboard',
            query: {
                'options': options,
            },
        });
    }
    /**
     * @deprecated
     * Returns vendor invoice details
     * Returns vendor invoice details
     * @returns any Success
     * @throws ApiError
     */
    public getApiV1InvoicesCharges({
        vendorInvoiceId,
        orderId,
    }: {
        vendorInvoiceId: number,
        orderId: number,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/{vendorInvoiceId}/charges',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            query: {
                'orderId': orderId,
            },
        });
    }
    /**
     * @deprecated
     * Returns vendor invoice details
     * Returns vendor invoice details
     * @returns any Success
     * @throws ApiError
     */
    public getApiV1InvoicesDetail({
        vendorInvoiceId,
        odatainvoice,
        odatavendor,
        odatacustomer,
    }: {
        vendorInvoiceId: number,
        odatainvoice?: Array<string>,
        odatavendor?: Array<string>,
        odatacustomer?: Array<string>,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/{vendorInvoiceId}/detail',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            query: {
                'odatainvoice': odatainvoice,
                'odatavendor': odatavendor,
                'odatacustomer': odatacustomer,
            },
        });
    }
    /**
     * @deprecated
     * Update invoice's vendor v1
     * Update invoice's vendor
     * @returns any Success
     * @throws ApiError
     */
    public postApiV1InvoicesUpdateVendor({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateVendorRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/update-vendor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update invoice's freight charge terms
     * Update invoice's freight charge terms
     * @returns any Success
     * @throws ApiError
     */
    public postApiV1InvoicesUpdateFreightChargeTerms({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateFreightTermsRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/update-freight-charge-terms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's customer
     * Update invoice's customer
     * @returns any Success
     * @throws ApiError
     */
    public patchApiV1InvoicesCustomer({
        invoiceId,
        requestBody,
    }: {
        invoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateCustomerRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/invoices/{invoiceId}/customer',
            path: {
                'invoiceId': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Downloads invoices
     * Downloads invoices
     * @returns any Success
     * @throws ApiError
     */
    public getApiV1InvoicesDownload({
        customerIds,
        vendorIds,
        exceptionCategoryIds,
        disputeReasonIds,
        invoiceFormatIds,
        vendorInvoiceStatusDescriptionIds,
        ownerUserUuids,
        shipmentNumber,
        orderNumber,
        invoiceNumber,
        searchBy,
        isMatched,
        hasNoExceptions,
        invoiceStateId,
        sortBy,
        sortOrder,
    }: {
        customerIds?: Array<number>,
        vendorIds?: Array<number>,
        exceptionCategoryIds?: Array<number>,
        disputeReasonIds?: Array<number>,
        invoiceFormatIds?: Array<number>,
        vendorInvoiceStatusDescriptionIds?: Array<number>,
        ownerUserUuids?: Array<string>,
        shipmentNumber?: string,
        orderNumber?: string,
        invoiceNumber?: string,
        searchBy?: string,
        isMatched?: boolean,
        hasNoExceptions?: boolean,
        invoiceStateId?: Invoice_Models_Enums_InvoiceState,
        sortBy?: Invoice_Models_Enums_InvoicesSortBy,
        sortOrder?: Invoice_Models_Enums_SortOrder,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/download',
            query: {
                'CustomerIds': customerIds,
                'VendorIds': vendorIds,
                'ExceptionCategoryIds': exceptionCategoryIds,
                'DisputeReasonIds': disputeReasonIds,
                'InvoiceFormatIds': invoiceFormatIds,
                'VendorInvoiceStatusDescriptionIds': vendorInvoiceStatusDescriptionIds,
                'OwnerUserUuids': ownerUserUuids,
                'ShipmentNumber': shipmentNumber,
                'OrderNumber': orderNumber,
                'InvoiceNumber': invoiceNumber,
                'SearchBy': searchBy,
                'IsMatched': isMatched,
                'HasNoExceptions': hasNoExceptions,
                'InvoiceStateId': invoiceStateId,
                'SortBy': sortBy,
                'SortOrder': sortOrder,
            },
        });
    }
    /**
     * Returns the tenantId of the vendor invoice
     * Returns the tenantId of the vendor invoice
     * @returns Invoice_Models_TenantInfoDto Success
     * @throws ApiError
     */
    public getApiV11InvoicesTenant({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Invoice_Models_TenantInfoDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/invoices/{vendorInvoiceId}/tenant',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Returns the tenantId of the vendor invoice
     * Returns the tenantId of the vendor invoice
     * @returns Invoice_Models_InvoiceOverviewPagedResponseDto Success
     * @throws ApiError
     */
    public getApiV11InvoicesDashboardOverview({
        customerIds,
        vendorIds,
        exceptionCategoryIds,
        disputeReasonIds,
        invoiceFormatIds,
        vendorInvoiceStatusDescriptionIds,
        ownerUserUuids,
        shipmentNumber,
        orderNumber,
        invoiceNumber,
        searchBy,
        isUnowned,
        isMatched,
        hasNoExceptions,
        parentInvoiceId,
        isReadyToApprove,
        returnVendorNetCharge,
        divisions,
        invoiceStateId,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
    }: {
        customerIds?: Array<number>,
        vendorIds?: Array<number>,
        exceptionCategoryIds?: Array<number>,
        disputeReasonIds?: Array<number>,
        invoiceFormatIds?: Array<number>,
        vendorInvoiceStatusDescriptionIds?: Array<number>,
        ownerUserUuids?: Array<string>,
        shipmentNumber?: string,
        orderNumber?: string,
        invoiceNumber?: string,
        searchBy?: string,
        isUnowned?: boolean,
        isMatched?: boolean,
        hasNoExceptions?: boolean,
        parentInvoiceId?: number,
        isReadyToApprove?: boolean,
        returnVendorNetCharge?: boolean,
        divisions?: Array<string>,
        invoiceStateId?: Invoice_Models_Enums_InvoiceState,
        sortBy?: Invoice_Models_Enums_InvoicesSortBy,
        sortOrder?: Invoice_Models_Enums_SortOrder,
        pageNumber?: number,
        pageSize?: number,
    }): Observable<Invoice_Models_InvoiceOverviewPagedResponseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/invoices/dashboard-overview',
            query: {
                'CustomerIds': customerIds,
                'VendorIds': vendorIds,
                'ExceptionCategoryIds': exceptionCategoryIds,
                'DisputeReasonIds': disputeReasonIds,
                'InvoiceFormatIds': invoiceFormatIds,
                'VendorInvoiceStatusDescriptionIds': vendorInvoiceStatusDescriptionIds,
                'OwnerUserUuids': ownerUserUuids,
                'ShipmentNumber': shipmentNumber,
                'OrderNumber': orderNumber,
                'InvoiceNumber': invoiceNumber,
                'SearchBy': searchBy,
                'IsUnowned': isUnowned,
                'IsMatched': isMatched,
                'HasNoExceptions': hasNoExceptions,
                'ParentInvoiceId': parentInvoiceId,
                'IsReadyToApprove': isReadyToApprove,
                'ReturnVendorNetCharge': returnVendorNetCharge,
                'Divisions': divisions,
                'InvoiceStateId': invoiceStateId,
                'SortBy': sortBy,
                'SortOrder': sortOrder,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }
    /**
     * Returns the tenantId of the vendor invoice
     * Returns the tenantId of the vendor invoice
     * @returns Invoice_Models_TenantInfoDto Success
     * @throws ApiError
     */
    public getApiV12InvoicesTenant({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Invoice_Models_TenantInfoDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.2/invoices/{vendorInvoiceId}/tenant',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Returns the tenantId of the vendor invoice
     * Returns the tenantId of the vendor invoice
     * @returns Invoice_Models_InvoiceOverviewPagedResponseDto Success
     * @throws ApiError
     */
    public getApiV12InvoicesDashboardOverview({
        customerIds,
        vendorIds,
        exceptionCategoryIds,
        disputeReasonIds,
        invoiceFormatIds,
        vendorInvoiceStatusDescriptionIds,
        ownerUserUuids,
        shipmentNumber,
        orderNumber,
        invoiceNumber,
        searchBy,
        isUnowned,
        isMatched,
        hasNoExceptions,
        parentInvoiceId,
        isReadyToApprove,
        returnVendorNetCharge,
        divisions,
        invoiceStateId,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
    }: {
        customerIds?: Array<number>,
        vendorIds?: Array<number>,
        exceptionCategoryIds?: Array<number>,
        disputeReasonIds?: Array<number>,
        invoiceFormatIds?: Array<number>,
        vendorInvoiceStatusDescriptionIds?: Array<number>,
        ownerUserUuids?: Array<string>,
        shipmentNumber?: string,
        orderNumber?: string,
        invoiceNumber?: string,
        searchBy?: string,
        isUnowned?: boolean,
        isMatched?: boolean,
        hasNoExceptions?: boolean,
        parentInvoiceId?: number,
        isReadyToApprove?: boolean,
        returnVendorNetCharge?: boolean,
        divisions?: Array<string>,
        invoiceStateId?: Invoice_Models_Enums_InvoiceState,
        sortBy?: Invoice_Models_Enums_InvoicesSortBy,
        sortOrder?: Invoice_Models_Enums_SortOrder,
        pageNumber?: number,
        pageSize?: number,
    }): Observable<Invoice_Models_InvoiceOverviewPagedResponseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.2/invoices/dashboard-overview',
            query: {
                'CustomerIds': customerIds,
                'VendorIds': vendorIds,
                'ExceptionCategoryIds': exceptionCategoryIds,
                'DisputeReasonIds': disputeReasonIds,
                'InvoiceFormatIds': invoiceFormatIds,
                'VendorInvoiceStatusDescriptionIds': vendorInvoiceStatusDescriptionIds,
                'OwnerUserUuids': ownerUserUuids,
                'ShipmentNumber': shipmentNumber,
                'OrderNumber': orderNumber,
                'InvoiceNumber': invoiceNumber,
                'SearchBy': searchBy,
                'IsUnowned': isUnowned,
                'IsMatched': isMatched,
                'HasNoExceptions': hasNoExceptions,
                'ParentInvoiceId': parentInvoiceId,
                'IsReadyToApprove': isReadyToApprove,
                'ReturnVendorNetCharge': returnVendorNetCharge,
                'Divisions': divisions,
                'InvoiceStateId': invoiceStateId,
                'SortBy': sortBy,
                'SortOrder': sortOrder,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }
    /**
     * Update invoice's ship date
     * Update invoice's ship date
     * @returns any Success
     * @throws ApiError
     */
    public putApiV1InvoicesDates({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceDatesRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/dates',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's ship date
     * Update invoice's ship date
     * @returns any Success
     * @throws ApiError
     */
    public putApiV11InvoicesDates({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceDatesRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/invoices/{vendorInvoiceId}/dates',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
