/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_MassApproveDto } from '../models/Invoice_Models_MassApproveDto';
import type { Invoice_Models_Requests_VendorInvoiceStatusReasonRequest } from '../models/Invoice_Models_Requests_VendorInvoiceStatusReasonRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceStatusesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Set invoice status to approved
     * Set invoice status to approved
     * @returns void
     * @throws ApiError
     */
    public postApiV2InvoicesStatusesApprove({
        invoiceUuid,
    }: {
        invoiceUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/{invoiceUuid}/statuses/approve',
            path: {
                'invoiceUuid': invoiceUuid,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Set a list of invoices status to approved
     * Set a list of invoices status to approved
     * @returns Invoice_Models_MassApproveDto Success
     * @throws ApiError
     */
    public postApiV2InvoicesMassApprove({
        requestBody,
    }: {
        requestBody?: Array<string>,
    }): Observable<Array<Invoice_Models_MassApproveDto>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/mass-approve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Set invoice status to terminated
     * Set invoice status to terminated
     * @returns void
     * @throws ApiError
     */
    public postApiV2InvoicesStatusesTerminate({
        invoiceUuid,
        requestBody,
    }: {
        invoiceUuid: string,
        requestBody?: Invoice_Models_Requests_VendorInvoiceStatusReasonRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/{invoiceUuid}/statuses/terminate',
            path: {
                'invoiceUuid': invoiceUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
}
