/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_TransportationServiceTypeDto } from '../models/Miscellaneous_Models_TransportationServiceTypeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TransportationServiceTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Transportation service types
     * Display Transportation service types
     * @returns Miscellaneous_Models_TransportationServiceTypeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousTransportationServiceTypes(): Observable<Array<Miscellaneous_Models_TransportationServiceTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/transportation-service-types',
        });
    }
}
