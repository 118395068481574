/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_RelatedDocumentDto } from '../models/Miscellaneous_Models_RelatedDocumentDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class RelatedDocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Related Documents
     * Display Related Documents
     * @returns Miscellaneous_Models_RelatedDocumentDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousRelatedDocuments({
        parentUuid,
        documentType,
    }: {
        parentUuid?: string,
        documentType?: string,
    }): Observable<Array<Miscellaneous_Models_RelatedDocumentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/related-documents',
            query: {
                'parentUuid': parentUuid,
                'documentType': documentType,
            },
        });
    }
}
