/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_VendorDisplayDto } from '../models/Vendor_Models_VendorDisplayDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorsAccountNumbersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get vendor by account number
     * Get vendor by account number
     * @returns Vendor_Models_VendorDisplayDto Success
     * @throws ApiError
     */
    public getApiV1VendorsVendorAccountNumbersVendor({
        accountNumber,
    }: {
        accountNumber: string,
    }): Observable<Vendor_Models_VendorDisplayDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/vendors/vendor-account-numbers/{accountNumber}/vendor',
            path: {
                'accountNumber': accountNumber,
            },
        });
    }
}
