/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_Requests_AddVendorChargeTypeRequest } from '../models/Miscellaneous_Models_Requests_AddVendorChargeTypeRequest';
import type { Miscellaneous_Models_Requests_UpdateVendorChargeTypeRequest } from '../models/Miscellaneous_Models_Requests_UpdateVendorChargeTypeRequest';
import type { Miscellaneous_Models_VendorChargeTypeDto } from '../models/Miscellaneous_Models_VendorChargeTypeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorChargeTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Vendor charge types
     * Display Vendor charge types
     * @returns Miscellaneous_Models_VendorChargeTypeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousVendorChargeTypes(): Observable<Array<Miscellaneous_Models_VendorChargeTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/vendor-charge-types',
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Add vendor charge type
     * Add vendor charge type
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1MiscellaneousVendorChargeTypes({
        requestBody,
    }: {
        requestBody?: Miscellaneous_Models_Requests_AddVendorChargeTypeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/miscellaneous/vendor-charge-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display Vendor charge type
     * Display Vendor charge type
     * @returns Miscellaneous_Models_VendorChargeTypeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousVendorChargeTypes1({
        vendorChargeTypeUuid,
    }: {
        vendorChargeTypeUuid: string,
    }): Observable<Miscellaneous_Models_VendorChargeTypeDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/vendor-charge-types/{vendorChargeTypeUuid}',
            path: {
                'vendorChargeTypeUuid': vendorChargeTypeUuid,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Update vendor charge type
     * Update vendor charge type
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1MiscellaneousVendorChargeTypes({
        vendorChargeTypeUuid,
        requestBody,
    }: {
        vendorChargeTypeUuid: string,
        requestBody?: Miscellaneous_Models_Requests_UpdateVendorChargeTypeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/miscellaneous/vendor-charge-types/{vendorChargeTypeUuid}',
            path: {
                'vendorChargeTypeUuid': vendorChargeTypeUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Delete vendor charge type
     * Delete vendor charge type
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1MiscellaneousVendorChargeTypes({
        vendorChargeTypeUuid,
    }: {
        vendorChargeTypeUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/miscellaneous/vendor-charge-types/{vendorChargeTypeUuid}',
            path: {
                'vendorChargeTypeUuid': vendorChargeTypeUuid,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
