/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularHttpRequest } from './core/AngularHttpRequest';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { OpenAPI } from './core/OpenAPI';
import { AuditChargesService } from './services/AuditChargesService';
import { AutomationCallToFlowActionService } from './services/AutomationCallToFlowActionService';
import { AutomationChangeInvoiceStatusActionService } from './services/AutomationChangeInvoiceStatusActionService';
import { AutomationChangeReferenceNumberActionService } from './services/AutomationChangeReferenceNumberActionService';
import { AutomationFlowsService } from './services/AutomationFlowsService';
import { AutomationRaiseExceptionActionService } from './services/AutomationRaiseExceptionActionService';
import { AutomationResolveExceptionActionService } from './services/AutomationResolveExceptionActionService';
import { AutomationRulesService } from './services/AutomationRulesService';
import { AutomationSendNotificationActionService } from './services/AutomationSendNotificationActionService';
import { AutomationVariablesService } from './services/AutomationVariablesService';
import { BillingTermsService } from './services/BillingTermsService';
import { ChargeTypesService } from './services/ChargeTypesService';
import { CountriesService } from './services/CountriesService';
import { CurrenciesService } from './services/CurrenciesService';
import { CustomerAdjustedChargesService } from './services/CustomerAdjustedChargesService';
import { CustomerAuditChargesService } from './services/CustomerAuditChargesService';
import { CustomerContactsService } from './services/CustomerContactsService';
import { CustomerLocationsService } from './services/CustomerLocationsService';
import { CustomerLocationTypesService } from './services/CustomerLocationTypesService';
import { CustomerOrderChargesService } from './services/CustomerOrderChargesService';
import { CustomersAccountNumbersService } from './services/CustomersAccountNumbersService';
import { DepartmentsService } from './services/DepartmentsService';
import { DocumentsService } from './services/DocumentsService';
import { DocumentTypesService } from './services/DocumentTypesService';
import { FeatureFlagService } from './services/FeatureFlagService';
import { FeesService } from './services/FeesService';
import { HandlingUnitsService } from './services/HandlingUnitsService';
import { IdentityService } from './services/IdentityService';
import { InvoicesService } from './services/InvoicesService';
import { MetadataService } from './services/MetadataService';
import { NotificationsService } from './services/NotificationsService';
import { OrderService } from './services/OrderService';
import { OrderChargesService } from './services/OrderChargesService';
import { OrderCustomerChargesService } from './services/OrderCustomerChargesService';
import { OrderLineItemsService } from './services/OrderLineItemsService';
import { OrderReferenceNumbersService } from './services/OrderReferenceNumbersService';
import { OrdersService } from './services/OrdersService';
import { OrderStopsService } from './services/OrderStopsService';
import { PermissionsService } from './services/PermissionsService';
import { ReferenceNumberTypesService } from './services/ReferenceNumberTypesService';
import { RelatedDocumentsService } from './services/RelatedDocumentsService';
import { ReportCategoriesService } from './services/ReportCategoriesService';
import { ReportsService } from './services/ReportsService';
import { RolesService } from './services/RolesService';
import { SecurityService } from './services/SecurityService';
import { TenantChargeTypesService } from './services/TenantChargeTypesService';
import { TenantContactsService } from './services/TenantContactsService';
import { TenantLocationsService } from './services/TenantLocationsService';
import { TenantsService } from './services/TenantsService';
import { TenantSettingsService } from './services/TenantSettingsService';
import { TimezonesService } from './services/TimezonesService';
import { TransportationModesService } from './services/TransportationModesService';
import { TransportationOptionsService } from './services/TransportationOptionsService';
import { TransportationServiceLevelsService } from './services/TransportationServiceLevelsService';
import { TransportationServiceTypesService } from './services/TransportationServiceTypesService';
import { UnmatchedDocumentsService } from './services/UnmatchedDocumentsService';
import { UserClassesService } from './services/UserClassesService';
import { UsersService } from './services/UsersService';
import { ValidationService } from './services/ValidationService';
import { VendorChargeTypesService } from './services/VendorChargeTypesService';
import { VendorContactsService } from './services/VendorContactsService';
import { VendorInvoiceChargesService } from './services/VendorInvoiceChargesService';
import { VendorInvoiceExceptionsService } from './services/VendorInvoiceExceptionsService';
import { VendorInvoiceFormatsService } from './services/VendorInvoiceFormatsService';
import { VendorInvoiceLineItemsService } from './services/VendorInvoiceLineItemsService';
import { VendorInvoiceNotesService } from './services/VendorInvoiceNotesService';
import { VendorInvoiceReferenceNumbersService } from './services/VendorInvoiceReferenceNumbersService';
import { VendorInvoicesService } from './services/VendorInvoicesService';
import { VendorInvoicesChangeLogsService } from './services/VendorInvoicesChangeLogsService';
import { VendorInvoiceStatusesService } from './services/VendorInvoiceStatusesService';
import { VendorInvoiceStopsService } from './services/VendorInvoiceStopsService';
import { VendorInvoiceTypesService } from './services/VendorInvoiceTypesService';
import { VendorLocationsService } from './services/VendorLocationsService';
import { VendorLocationTypesService } from './services/VendorLocationTypesService';
import { VendorsAccountNumbersService } from './services/VendorsAccountNumbersService';
import { VendorStatusTypesService } from './services/VendorStatusTypesService';
@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: OpenAPI,
            useValue: {
                BASE: OpenAPI?.BASE ?? 'https://api.navix.io',
                VERSION: OpenAPI?.VERSION ?? '1',
                WITH_CREDENTIALS: OpenAPI?.WITH_CREDENTIALS ?? false,
                CREDENTIALS: OpenAPI?.CREDENTIALS ?? 'include',
                TOKEN: OpenAPI?.TOKEN,
                USERNAME: OpenAPI?.USERNAME,
                PASSWORD: OpenAPI?.PASSWORD,
                HEADERS: OpenAPI?.HEADERS,
                ENCODE_PATH: OpenAPI?.ENCODE_PATH,
            } as OpenAPIConfig,
        },
        {
            provide: BaseHttpRequest,
            useClass: AngularHttpRequest,
        },
        AuditChargesService,
        AutomationCallToFlowActionService,
        AutomationChangeInvoiceStatusActionService,
        AutomationChangeReferenceNumberActionService,
        AutomationFlowsService,
        AutomationRaiseExceptionActionService,
        AutomationResolveExceptionActionService,
        AutomationRulesService,
        AutomationSendNotificationActionService,
        AutomationVariablesService,
        BillingTermsService,
        ChargeTypesService,
        CountriesService,
        CurrenciesService,
        CustomerAdjustedChargesService,
        CustomerAuditChargesService,
        CustomerContactsService,
        CustomerLocationsService,
        CustomerLocationTypesService,
        CustomerOrderChargesService,
        CustomersAccountNumbersService,
        DepartmentsService,
        DocumentsService,
        DocumentTypesService,
        FeatureFlagService,
        FeesService,
        HandlingUnitsService,
        IdentityService,
        InvoicesService,
        MetadataService,
        NotificationsService,
        OrderService,
        OrderChargesService,
        OrderCustomerChargesService,
        OrderLineItemsService,
        OrderReferenceNumbersService,
        OrdersService,
        OrderStopsService,
        PermissionsService,
        ReferenceNumberTypesService,
        RelatedDocumentsService,
        ReportCategoriesService,
        ReportsService,
        RolesService,
        SecurityService,
        TenantChargeTypesService,
        TenantContactsService,
        TenantLocationsService,
        TenantsService,
        TenantSettingsService,
        TimezonesService,
        TransportationModesService,
        TransportationOptionsService,
        TransportationServiceLevelsService,
        TransportationServiceTypesService,
        UnmatchedDocumentsService,
        UserClassesService,
        UsersService,
        ValidationService,
        VendorChargeTypesService,
        VendorContactsService,
        VendorInvoiceChargesService,
        VendorInvoiceExceptionsService,
        VendorInvoiceFormatsService,
        VendorInvoiceLineItemsService,
        VendorInvoiceNotesService,
        VendorInvoiceReferenceNumbersService,
        VendorInvoicesService,
        VendorInvoicesChangeLogsService,
        VendorInvoiceStatusesService,
        VendorInvoiceStopsService,
        VendorInvoiceTypesService,
        VendorLocationsService,
        VendorLocationTypesService,
        VendorsAccountNumbersService,
        VendorStatusTypesService,
    ]
})
export class api {}

