/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Order_Models_OrderDto } from '../models/Order_Models_OrderDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display order
     * Display order
     * @returns Order_Models_OrderDto Success
     * @throws ApiError
     */
    public getApiV11Orders({
        orderId,
    }: {
        orderId: number,
    }): Observable<Order_Models_OrderDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/orders/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
