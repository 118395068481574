/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Order_Models_CustomerOrderChargeDtoNew } from '../models/Order_Models_CustomerOrderChargeDtoNew';
import type { Order_Models_CustomerOrderChargeRequest } from '../models/Order_Models_CustomerOrderChargeRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomerOrderChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer order charges
     * Display customer order charges
     * @returns Order_Models_CustomerOrderChargeDtoNew Success
     * @throws ApiError
     */
    public getOrderChargesNew({
        orderId,
    }: {
        orderId: number,
    }): Observable<Array<Order_Models_CustomerOrderChargeDtoNew>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/customer-order-charges/{orderId}/new',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Update customer order charges
     * Update customer order charges
     * @returns void
     * @throws ApiError
     */
    public putApiV1CustomerOrderCharges({
        customerOrderChargeId,
        requestBody,
    }: {
        customerOrderChargeId: number,
        requestBody?: Order_Models_CustomerOrderChargeRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/customer-order-charges/{customerOrderChargeId}',
            path: {
                'customerOrderChargeId': customerOrderChargeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
}
