/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Order_Models_CustomerOrderChargeDto } from '../models/Order_Models_CustomerOrderChargeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrderCustomerChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer order charges
     * Display customer order charges
     * @returns Order_Models_CustomerOrderChargeDto Success
     * @throws ApiError
     */
    public getCustomerCharges({
        orderId,
    }: {
        orderId: number,
    }): Observable<Array<Order_Models_CustomerOrderChargeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/customer-order-charges',
            query: {
                'orderId': orderId,
            },
        });
    }
}
