/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_HandlingUnitsDto } from '../models/Miscellaneous_Models_HandlingUnitsDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class HandlingUnitsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Handling Units
     * Display Handling Units
     * @returns Miscellaneous_Models_HandlingUnitsDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousHandlingUnits(): Observable<Array<Miscellaneous_Models_HandlingUnitsDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/handling-units',
        });
    }
}
