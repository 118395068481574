/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { of, type Observable } from 'rxjs'
import type { Navix_Services_Sdk_Order } from '../models/Navix_Services_Sdk_Order'
import type { Navix_Services_Sdk_OrderDocument } from '../models/Navix_Services_Sdk_OrderDocument'
import type { Navix_Services_Sdk_SearchRequestDTO } from '../models/Navix_Services_Sdk_SearchRequestDTO'
import type { Navix_Services_Sdk_Tag } from '../models/Navix_Services_Sdk_Tag'
import { BaseHttpRequest } from '../core/BaseHttpRequest'
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(public readonly httpRequest: BaseHttpRequest) { }
  /**
   * Display order
   * Display order
   * @returns Navix_Services_Sdk_OrderDocument Success
   * @throws ApiError
   */
  public getApiV2Orders({
    orderId
  }: {
    orderId: string
  }): Observable<Navix_Services_Sdk_OrderDocument> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orders/{orderId}',
      path: {
        orderId: orderId
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`
      }
    })
  }
  /**
   * Modify order
   * Modify order
   * @returns any Success
   * @throws ApiError
   */
  public putApiV2Orders({
    id,
    requestBody
  }: {
    id: string
    requestBody?: Navix_Services_Sdk_Order
  }): Observable<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/orders/{id}',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`
      }
    })
  }
  /**
   * Search for orders
   * Search for orders
   * @returns Navix_Services_Sdk_OrderDocument Success
   * @throws ApiError
   */
  public postApiV2OrdersSearch({
    requestBody
  }: {
    requestBody?: Navix_Services_Sdk_SearchRequestDTO
  }): Observable<Array<Navix_Services_Sdk_OrderDocument>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orders/Search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`
      }
    })
  }
  /**
   * Post new order
   * Post new order
   * @returns any Success
   * @throws ApiError
   */
  public postApiV2OrdersAdd({
    requestBody
  }: {
    requestBody?: Navix_Services_Sdk_Order
  }): Observable<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orders/Add',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`
      }
    })
  }
  /**
   * Services Health Check
   * Services Health Check
   * @returns any Success
   * @throws ApiError
   */
  public getApiV2OrdersServicesHealthCheck(): Observable<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orders/ServicesHealthCheck',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`
      }
    })
  }
  /**
   * Add a v2 order doc from edge, save to v1 shape
   * Add a v2 order doc from edge, save to v1 shape
   * @returns string Success
   * @throws ApiError
   */
  public postApiV2OrdersAddFromEdge({
    requestBody
  }: {
    requestBody?: Navix_Services_Sdk_OrderDocument
  }): Observable<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orders/AddFromEdge',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        409: `Conflict`,
        500: `Server Error`
      }
    })
  }
  /**
   * Gets a list of order division tags for a specific tenant
   * Gets a list of order division tags for a specific tenant
   * @returns Navix_Services_Sdk_Tag Success
   * @throws ApiError
   */
  public getApiV2Divisions(): Observable<Array<Navix_Services_Sdk_Tag>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/divisions',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Server Error`
      }
    })
  }

}
