/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { RatingEngine_Models_CustomerAuditChargeDto } from '../models/RatingEngine_Models_CustomerAuditChargeDto';
import type { RatingEngine_Models_CustomerAuditChargeDtoNew } from '../models/RatingEngine_Models_CustomerAuditChargeDtoNew';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomerAuditChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer audit charges
     * Display customer audit charges
     * @returns RatingEngine_Models_CustomerAuditChargeDto Success
     * @throws ApiError
     */
    public customerAuditCharges({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<RatingEngine_Models_CustomerAuditChargeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/customer-audit-charges',
            query: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Display customer audit charges
     * Display customer audit charges
     * @returns RatingEngine_Models_CustomerAuditChargeDtoNew Success
     * @throws ApiError
     */
    public customerAuditChargesNew({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<RatingEngine_Models_CustomerAuditChargeDtoNew>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/customer-audit-charges/{vendorInvoiceId}/new',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
}
