/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_Actions_SendNotificationActionDto } from '../models/Navix_Automation_Models_Actions_SendNotificationActionDto';
import type { Navix_Automation_Models_Requests_Actions_SendNotificationActionEditorRequest } from '../models/Navix_Automation_Models_Requests_Actions_SendNotificationActionEditorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationSendNotificationActionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display send notification
     * Display send notification
     * @returns Navix_Automation_Models_Actions_SendNotificationActionDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRulesSendNotificationAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<Navix_Automation_Models_Actions_SendNotificationActionDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/send-notification-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update send notification
     * Update send notification
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRulesSendNotificationAction({
        flowUuid,
        ruleUuid,
        actionUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_SendNotificationActionEditorRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/send-notification-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Deletesend notification
     * Delete send notification
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationFlowsRulesSendNotificationAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/send-notification-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Add send notification
     * Add send notification
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationFlowsRulesSendNotificationAction({
        flowUuid,
        ruleUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_SendNotificationActionEditorRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/send-notification-action',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
}
