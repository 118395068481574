/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_Requests_AddVendorContactRequest } from '../models/Vendor_Models_Requests_AddVendorContactRequest';
import type { Vendor_Models_Requests_UpdateVendorContactRequest } from '../models/Vendor_Models_Requests_UpdateVendorContactRequest';
import type { Vendor_Models_VendorContactDto } from '../models/Vendor_Models_VendorContactDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorContactsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor contacts
     * Display vendor contacts
     * @returns Vendor_Models_VendorContactDto Success
     * @throws ApiError
     */
    public getApiV11VendorsVendorContacts({
        vendorId,
    }: {
        vendorId: number,
    }): Observable<Array<Vendor_Models_VendorContactDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/vendors/{vendorId}/vendor-contacts',
            path: {
                'vendorId': vendorId,
            },
        });
    }
    /**
     * Add vendor contact
     * Add vendor contact
     * @returns string Created
     * @throws ApiError
     */
    public postApiV11VendorsVendorContacts({
        requestBody,
    }: {
        requestBody?: Vendor_Models_Requests_AddVendorContactRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/vendors/vendor-contacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Update vendor contact
     * Update vendor contact
     * @returns string Success
     * @throws ApiError
     */
    public putApiV11VendorsVendorContacts({
        requestBody,
    }: {
        requestBody?: Vendor_Models_Requests_UpdateVendorContactRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/vendors/vendor-contacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Delete vendor contact
     * Delete vendor contact
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV11VendorsVendorContacts({
        vendorContactId,
    }: {
        vendorContactId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1.1/vendors/vendor-contacts/{vendorContactId}',
            path: {
                'vendorContactId': vendorContactId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
}
