/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_BillingTermDto } from '../models/Miscellaneous_Models_BillingTermDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class BillingTermsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Billing terms
     * Display Billing terms
     * @returns Miscellaneous_Models_BillingTermDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousBillingTerms(): Observable<Array<Miscellaneous_Models_BillingTermDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/billing-terms',
        });
    }
}
