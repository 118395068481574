/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_Requests_AddTenantChargeTypeMappingRequest } from '../models/Miscellaneous_Models_Requests_AddTenantChargeTypeMappingRequest';
import type { Miscellaneous_Models_Requests_SwitchDefaultTenantChargeTypeRequest } from '../models/Miscellaneous_Models_Requests_SwitchDefaultTenantChargeTypeRequest';
import type { Miscellaneous_Models_Requests_UpdateTenantChargeTypeMappingRequest } from '../models/Miscellaneous_Models_Requests_UpdateTenantChargeTypeMappingRequest';
import type { Miscellaneous_Models_TenantChargeTypeDto } from '../models/Miscellaneous_Models_TenantChargeTypeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TenantChargeTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Tenant charge types
     * Display Tenant charge types
     * @returns Miscellaneous_Models_TenantChargeTypeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousTenantChargeTypes(): Observable<Array<Miscellaneous_Models_TenantChargeTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/tenant-charge-types',
        });
    }
    /**
     * Add tenant charge type mapping
     * Add tenant charge type mapping
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1MiscellaneousTenantChargeTypes({
        requestBody,
    }: {
        requestBody?: Miscellaneous_Models_Requests_AddTenantChargeTypeMappingRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/miscellaneous/tenant-charge-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Update tenant charge type mapping
     * Update tenant charge type mapping
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1MiscellaneousTenantChargeTypes({
        requestBody,
    }: {
        requestBody?: Miscellaneous_Models_Requests_UpdateTenantChargeTypeMappingRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/miscellaneous/tenant-charge-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Switch on or off the default tenant charge type mapping
     * Switch on or off the default tenant charge type mapping
     * @returns void
     * @throws ApiError
     */
    public putApiV1MiscellaneousTenantChargeTypes1({
        tenantChargeTypeUuid,
        requestBody,
    }: {
        tenantChargeTypeUuid: string,
        requestBody?: Miscellaneous_Models_Requests_SwitchDefaultTenantChargeTypeRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/miscellaneous/tenant-charge-types/{tenantChargeTypeUuid}',
            path: {
                'tenantChargeTypeUuid': tenantChargeTypeUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Delete tenant charge type mapping
     * Delete tenant charge type mapping
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1MiscellaneousTenantChargeTypes({
        tenantChargeTypeUuid,
    }: {
        tenantChargeTypeUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/miscellaneous/tenant-charge-types/{tenantChargeTypeUuid}',
            path: {
                'tenantChargeTypeUuid': tenantChargeTypeUuid,
            },
            errors: {
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
}
