/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_GetTenants_GetTenantsResponse } from '../models/Identity_Application_GetTenants_GetTenantsResponse';
import type { Identity_Application_Models_TenantContactDto } from '../models/Identity_Application_Models_TenantContactDto';
import type { Identity_Application_Models_TenantDetailDto } from '../models/Identity_Application_Models_TenantDetailDto';
import type { Identity_Application_Models_TenantLocationDto } from '../models/Identity_Application_Models_TenantLocationDto';
import type { Identity_Application_Models_TenantUserDto } from '../models/Identity_Application_Models_TenantUserDto';
import type { Identity_Application_Models_TenantUserPagedResponseDto } from '../models/Identity_Application_Models_TenantUserPagedResponseDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TenantsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display tenant detail
     * Display tenant detail
     * @returns Identity_Application_Models_TenantDetailDto Success
     * @throws ApiError
     */
    public tenantDetail({
        tenantGuid,
    }: {
        tenantGuid: string,
    }): Observable<Identity_Application_Models_TenantDetailDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/tenants/{tenantGuid}',
            path: {
                'tenantGuid': tenantGuid,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Update tenant detail
     * Update tenant detail
     * @returns any Success
     * @throws ApiError
     */
    public putApiV1AuthTenants({
        tenantGuid,
        requestBody,
    }: {
        tenantGuid: string,
        requestBody?: any,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/tenants/{tenantGuid}',
            path: {
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display Tenants
     * Display tenants
     * @returns Identity_Application_GetTenants_GetTenantsResponse Success
     * @throws ApiError
     */
    public getApiV1AuthTenantsList(): Observable<Array<Identity_Application_GetTenants_GetTenantsResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/tenants-list',
        });
    }
    /**
     * Display tenant contacts
     * Display tenant contacts
     * @returns Identity_Application_Models_TenantContactDto Success
     * @throws ApiError
     */
    public tenantContacts({
        tenantGuid,
    }: {
        tenantGuid: string,
    }): Observable<Array<Identity_Application_Models_TenantContactDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/tenants/{tenantGuid}/contacts',
            path: {
                'tenantGuid': tenantGuid,
            },
        });
    }
    /**
     * Display tenant locations
     * Display tenant locations
     * @returns Identity_Application_Models_TenantLocationDto Success
     * @throws ApiError
     */
    public tenantLocations({
        tenantGuid,
    }: {
        tenantGuid: string,
    }): Observable<Array<Identity_Application_Models_TenantLocationDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/tenants/{tenantGuid}/locations',
            path: {
                'tenantGuid': tenantGuid,
            },
        });
    }
    /**
     * Display users of current tenant
     * Display users of current tenant
     * @returns Identity_Application_Models_TenantUserPagedResponseDto Success
     * @throws ApiError
     */
    public getApiV1AuthTenantsCurrentUsers({
        roles,
        singleRole,
        email,
        isActive,
        pageNumber,
        pageSize,
    }: {
        roles?: Array<string>,
        singleRole?: boolean,
        email?: string,
        isActive?: boolean,
        pageNumber?: number,
        pageSize?: number,
    }): Observable<Identity_Application_Models_TenantUserPagedResponseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/tenants/current/users',
            query: {
                'Roles': roles,
                'SingleRole': singleRole,
                'Email': email,
                'IsActive': isActive,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public getApiV1TenantsCurrent(): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/tenants/current',
        });
    }
    /**
     * Add Tenant
     * Add tenant
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthTenants({
        requestBody,
    }: {
        requestBody?: any,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/tenants',
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display users of a tenant
     * Display users of a tenant
     * @returns Identity_Application_Models_TenantUserDto Success
     * @throws ApiError
     */
    public tenantUsers(): Observable<Array<Identity_Application_Models_TenantUserDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/tenant-users',
        });
    }
}
