/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_Requests_VendorNewRequest } from '../models/Vendor_Models_Requests_VendorNewRequest';
import type { Vendor_Models_Requests_VendorNewUpdateRequest } from '../models/Vendor_Models_Requests_VendorNewUpdateRequest';
import type { Vendor_Models_VendorDetailDto } from '../models/Vendor_Models_VendorDetailDto';
import type { Vendor_Models_VendorDto } from '../models/Vendor_Models_VendorDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendors
     * Display vendors
     * @returns Vendor_Models_VendorDto Success
     * @throws ApiError
     */
    public getApiV11VendorsList(): Observable<Array<Vendor_Models_VendorDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/vendors/list',
        });
    }
    /**
     * Display vendor details
     * Display vendor details
     * @returns Vendor_Models_VendorDetailDto Success
     * @throws ApiError
     */
    public getApiV11VendorsDetail({
        vendorId,
    }: {
        vendorId: number,
    }): Observable<Vendor_Models_VendorDetailDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/vendors/{vendorId}/detail',
            path: {
                'vendorId': vendorId,
            },
        });
    }
    /**
     * Add vendor
     * Add vendor
     * @returns string Created
     * @throws ApiError
     */
    public postApiV11VendorsVendors({
        requestBody,
    }: {
        requestBody?: Vendor_Models_Requests_VendorNewRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/vendors/vendors',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update vendors
     * Update vendors
     * @returns void
     * @throws ApiError
     */
    public putApiV11VendorsVendors({
        requestBody,
    }: {
        requestBody?: Vendor_Models_Requests_VendorNewUpdateRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/vendors/vendors',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
