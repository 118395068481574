/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceStopBatchRequest } from '../models/Invoice_Models_Requests_VendorInvoiceStopBatchRequest';
import type { Invoice_Models_Requests_VendorInvoiceStopRequest } from '../models/Invoice_Models_Requests_VendorInvoiceStopRequest';
import type { Invoice_Models_VendorInvoiceStopDto } from '../models/Invoice_Models_VendorInvoiceStopDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceStopsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice stops
     * Display vendor invoice stops
     * @returns Invoice_Models_VendorInvoiceStopDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesStops({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_VendorInvoiceStopDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/stops/{vendorInvoiceId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Add Stop for Vendor invoice
     * Add Stop for Vendor invoice
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1InvoicesVendorInvoiceStopsBatch({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        /**
         * Vendor Invoice Stops to be added or updated in batch
         */
        requestBody: Invoice_Models_Requests_VendorInvoiceStopBatchRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/vendor-invoice-stops/{vendorInvoiceId}/batch',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Add or Update Stops for Vendor invoice
     * Add or Update Stops for Vendor invoice
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesVendorInvoiceStops({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        /**
         * Vendor invoice stop to Add
         */
        requestBody: Invoice_Models_Requests_VendorInvoiceStopRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/vendor-invoice-stops/{vendorInvoiceId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
