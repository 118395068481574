/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Order_Models_NewUnmatchedOrderDto } from '../models/Order_Models_NewUnmatchedOrderDto';
import type { Order_Models_UnmatchedOrderDto } from '../models/Order_Models_UnmatchedOrderDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @deprecated
     * Returns list of orders with no related invoice
     * Returns list of orders with no related invoice
     * @returns Order_Models_UnmatchedOrderDto Success
     * @throws ApiError
     */
    public getApiV1UnmatchedOrders(): Observable<Array<Order_Models_UnmatchedOrderDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/unmatched-orders',
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Returns list of orders with no related invoice
     * Returns list of orders with no related invoice
     * @returns Order_Models_NewUnmatchedOrderDto Success
     * @throws ApiError
     */
    public getApiV1NewUnmatchedOrders(): Observable<Array<Order_Models_NewUnmatchedOrderDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/new-unmatched-orders',
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Exports unmatched orders to Excel file
     * Exports unmatched orders to Excel file
     * @returns Order_Models_UnmatchedOrderDto Success
     * @throws ApiError
     */
    public getApiV1UnmatchedOrdersDownload({
        options,
    }: {
        options?: any,
    }): Observable<Array<Order_Models_UnmatchedOrderDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/unmatched-orders/download',
            query: {
                'options': options,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Exports unmatched orders to Excel file
     * Exports unmatched orders to Excel file
     * @returns Order_Models_NewUnmatchedOrderDto Success
     * @throws ApiError
     */
    public getApiV1NewUnmatchedOrdersDownload({
        options,
    }: {
        options?: any,
    }): Observable<Array<Order_Models_NewUnmatchedOrderDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/new-unmatched-orders/download',
            query: {
                'options': options,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }
}
