/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_AccessorialChargeTypeGroupDto } from '../models/Miscellaneous_Models_AccessorialChargeTypeGroupDto';
import type { Miscellaneous_Models_ChargeTypeDto } from '../models/Miscellaneous_Models_ChargeTypeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class ChargeTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Charge types groups
     * Display Charge types groups
     * @returns Miscellaneous_Models_AccessorialChargeTypeGroupDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousAccessorialChargeTypesGroups(): Observable<Array<Miscellaneous_Models_AccessorialChargeTypeGroupDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/accessorial-charge-types/groups',
        });
    }
    /**
     * Display Charge types groups
     * Display Charge types groups
     * @returns Miscellaneous_Models_AccessorialChargeTypeGroupDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousAccessorialChargeTypesGroupsNew(): Observable<Array<Miscellaneous_Models_AccessorialChargeTypeGroupDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/accessorial-charge-types/groups/new',
        });
    }
    /**
     * Display Charge types
     * Display Charge types
     * @returns Miscellaneous_Models_ChargeTypeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousChargeTypes({
        parentChargeTypeId,
    }: {
        parentChargeTypeId?: number,
    }): Observable<Array<Miscellaneous_Models_ChargeTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/charge-types',
            query: {
                'parentChargeTypeId': parentChargeTypeId,
            },
        });
    }
    /**
     * Display Parent Charge types
     * Display Parent Charge types
     * @returns Miscellaneous_Models_ChargeTypeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousChargeTypesParent(): Observable<Array<Miscellaneous_Models_ChargeTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/charge-types/parent',
        });
    }
}
