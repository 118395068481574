/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceOtherDetailsRequest } from '../models/Invoice_Models_Requests_VendorInvoiceOtherDetailsRequest';
import type { Invoice_Models_Requests_VendorInvoiceRequest } from '../models/Invoice_Models_Requests_VendorInvoiceRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateCurrencyRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateCurrencyRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateOwnershipRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateOwnershipRequest';
import type { Invoice_Models_Responses_VendorInvoiceResponse } from '../models/Invoice_Models_Responses_VendorInvoiceResponse';
import type { Invoice_Models_VendorInvoiceDto } from '../models/Invoice_Models_VendorInvoiceDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Generates an invoice
     * Generates an invoice
     * @returns any Success
     * @throws ApiError
     */
    public postApiV1InvoicesGenerate({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/generate',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Display vendor invoice
     * Display vendor invoice
     * @returns Invoice_Models_VendorInvoiceDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoice({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Invoice_Models_VendorInvoiceDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice/{vendorInvoiceId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Display vendor invoice
     * Display vendor invoice
     * @returns Invoice_Models_VendorInvoiceDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceTesting({
        vendorInvoiceUuid,
    }: {
        vendorInvoiceUuid: string,
    }): Observable<Invoice_Models_VendorInvoiceDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice/{vendorInvoiceUuid}/testing',
            path: {
                'vendorInvoiceUuid': vendorInvoiceUuid,
            },
        });
    }
    /**
     * @deprecated
     * Add vendor invoice
     * Add vendor invoice
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1InvoicesInvoices({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/invoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update invoice's other details
     * Update invoice's other details
     * @returns any Success
     * @throws ApiError
     */
    public postApiV1InvoicesUpdateOtherDetails({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceOtherDetailsRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/update-other-details',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's currency
     * Update invoice's currency
     * @returns any Success
     * @throws ApiError
     */
    public putApiV1InvoicesCurrency({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateCurrencyRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/currency',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Regenerate a terminated invoice
     * Regenerate a terminated invoice
     * @returns Invoice_Models_Responses_VendorInvoiceResponse Created
     * @throws ApiError
     */
    public postApiV1InvoicesRegenerate({
        terminatedVendorInvoiceId,
    }: {
        terminatedVendorInvoiceId: number,
    }): Observable<Invoice_Models_Responses_VendorInvoiceResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{terminatedVendorInvoiceId}/regenerate',
            path: {
                'terminatedVendorInvoiceId': terminatedVendorInvoiceId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's user owner
     * Update invoice's user owner
     * @returns any Success
     * @throws ApiError
     */
    public putApiV1InvoicesOwnership({
        vendorInvoiceUuid,
        requestBody,
    }: {
        vendorInvoiceUuid: string,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateOwnershipRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceUuid}/ownership',
            path: {
                'vendorInvoiceUuid': vendorInvoiceUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update invoice's other details
     * Update invoice's other details
     * @returns any Success
     * @throws ApiError
     */
    public postApiV11InvoicesUpdateOtherDetails({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceOtherDetailsRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/invoices/update-other-details',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's currency
     * Update invoice's currency
     * @returns any Success
     * @throws ApiError
     */
    public putApiV11InvoicesCurrency({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateCurrencyRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/invoices/{vendorInvoiceId}/currency',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Regenerate a terminated invoice
     * Regenerate a terminated invoice
     * @returns Invoice_Models_Responses_VendorInvoiceResponse Created
     * @throws ApiError
     */
    public postApiV11InvoicesRegenerate({
        terminatedVendorInvoiceId,
    }: {
        terminatedVendorInvoiceId: number,
    }): Observable<Invoice_Models_Responses_VendorInvoiceResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/invoices/{terminatedVendorInvoiceId}/regenerate',
            path: {
                'terminatedVendorInvoiceId': terminatedVendorInvoiceId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's user owner
     * Update invoice's user owner
     * @returns any Success
     * @throws ApiError
     */
    public putApiV11InvoicesOwnership({
        vendorInvoiceUuid,
        requestBody,
    }: {
        vendorInvoiceUuid: string,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateOwnershipRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/invoices/{vendorInvoiceUuid}/ownership',
            path: {
                'vendorInvoiceUuid': vendorInvoiceUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
}
