/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_GetOrganizationByUser_GetOrganizationByUserResponse } from '../models/Identity_Application_GetOrganizationByUser_GetOrganizationByUserResponse';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class IdentityService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns Identity_Application_GetOrganizationByUser_GetOrganizationByUserResponse Success
     * @throws ApiError
     */
    public getIdentityV1Organization({
        username,
    }: {
        username: string,
    }): Observable<Identity_Application_GetOrganizationByUser_GetOrganizationByUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/identity/v1/{username}/organization',
            path: {
                'username': username,
            },
        });
    }
}
