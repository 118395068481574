/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_Models_Request_TenantLocationRequest } from '../models/Identity_Application_Models_Request_TenantLocationRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TenantLocationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add Tenant Location
     * Add tenant location
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthTenantsLocations({
        tenantGuid,
        requestBody,
    }: {
        tenantGuid: string,
        requestBody?: Identity_Application_Models_Request_TenantLocationRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/tenants/{tenantGuid}/locations',
            path: {
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Edit Tenant Locations
     * Edit tenant locations
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1AuthTenantsLocations({
        tenantLocationGuid,
        tenantGuid,
        requestBody,
    }: {
        tenantLocationGuid: string,
        tenantGuid: string,
        requestBody?: Identity_Application_Models_Request_TenantLocationRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/tenants/{tenantGuid}/locations/{tenantLocationGuid}',
            path: {
                'tenantLocationGuid': tenantLocationGuid,
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
}
