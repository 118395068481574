/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceUrlDocumentRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUrlDocumentRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Attach a document from email to an invoice
     * Attach a document from email to an invoice
     * @returns any Success
     * @throws ApiError
     */
    public postApiV2InvoicesDocumentsAttach({
        invoiceUuid,
        requestBody,
    }: {
        invoiceUuid: string,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUrlDocumentRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/invoices/{invoiceUuid}/documents/attach',
            path: {
                'invoiceUuid': invoiceUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
