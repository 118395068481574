/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Customer_Models_CustomerDisplayDto } from '../models/Customer_Models_CustomerDisplayDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomersAccountNumbersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get customer by account number
     * Get customer by account number
     * @returns Customer_Models_CustomerDisplayDto Success
     * @throws ApiError
     */
    public getApiV1CustomersCustomerAccountNumbersCustomer({
        accountNumber,
    }: {
        accountNumber: string,
    }): Observable<Customer_Models_CustomerDisplayDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/customers/customer-account-numbers/{accountNumber}/customer',
            path: {
                'accountNumber': accountNumber,
            },
        });
    }
}
