/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceReferenceNumberBatchRequest } from '../models/Invoice_Models_Requests_VendorInvoiceReferenceNumberBatchRequest';
import type { Invoice_Models_Requests_VendorInvoiceReferenceNumberRequest } from '../models/Invoice_Models_Requests_VendorInvoiceReferenceNumberRequest';
import type { Invoice_Models_VendorInvoiceReferenceNumberDto } from '../models/Invoice_Models_VendorInvoiceReferenceNumberDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceReferenceNumbersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice reference numbers
     * Display vendor invoice reference numbers
     * @returns Invoice_Models_VendorInvoiceReferenceNumberDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesReferenceNumbers({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_VendorInvoiceReferenceNumberDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/{vendorInvoiceId}/reference-numbers',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Update vendor invoice reference number
     * Update vendor invoice reference number
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1InvoicesReferenceNumbers({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceReferenceNumberRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/reference-numbers',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add vendor invoice reference number
     * Add vendor invoice reference number
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1InvoicesReferenceNumbers({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceReferenceNumberRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/reference-numbers',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove vendor invoice reference number
     * Remove vendor invoice reference number
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1InvoicesReferenceNumbers({
        vendorInvoiceId,
        vendorInvoiceReferenceNumberId,
    }: {
        vendorInvoiceId: number,
        vendorInvoiceReferenceNumberId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/invoices/{vendorInvoiceId}/reference-numbers/{vendorInvoiceReferenceNumberId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
                'vendorInvoiceReferenceNumberId': vendorInvoiceReferenceNumberId,
            },
        });
    }
    /**
     * Update vendor invoice reference number
     * Update vendor invoice reference number
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1InvoicesReferenceNumbersBatch({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceReferenceNumberBatchRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/reference-numbers/batch',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
