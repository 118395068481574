/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Customer_Models_CustomerFeeDto } from '../models/Customer_Models_CustomerFeeDto';
import type { Customer_Models_Requests_AddCustomerFeeRequest } from '../models/Customer_Models_Requests_AddCustomerFeeRequest';
import type { Customer_Models_Requests_UpdateCustomerFeeRequest } from '../models/Customer_Models_Requests_UpdateCustomerFeeRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class FeesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get customer fees
     * Get customer fees
     * @returns Customer_Models_CustomerFeeDto Success
     * @throws ApiError
     */
    public getFees({
        customerId,
    }: {
        customerId: number,
    }): Observable<Array<Customer_Models_CustomerFeeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/customers/fees',
            query: {
                'customerId': customerId,
            },
        });
    }
    /**
     * Add customer fees
     * Add customer fees
     * @returns string Success
     * @throws ApiError
     */
    public addFees({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_AddCustomerFeeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/customers/fees',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Update customer fees
     * Update customer fees
     * @returns string Success
     * @throws ApiError
     */
    public updateFees({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_UpdateCustomerFeeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/customers/fees',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
