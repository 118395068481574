/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_Actions_CallToFlowActionDto } from '../models/Navix_Automation_Models_Actions_CallToFlowActionDto';
import type { Navix_Automation_Models_Requests_Actions_CallFlowActionEditorRequest } from '../models/Navix_Automation_Models_Requests_Actions_CallFlowActionEditorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationCallToFlowActionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display automation call to flow action
     * Display automation call to flow action
     * @returns Navix_Automation_Models_Actions_CallToFlowActionDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRulesCallToFlowAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<Navix_Automation_Models_Actions_CallToFlowActionDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/call-to-flow-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update call to flow action
     * Update call to flow action
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRulesCallToFlowAction({
        flowUuid,
        ruleUuid,
        actionUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_CallFlowActionEditorRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/call-to-flow-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Delete automation call to flow action
     * Delete automation call to flow action
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationFlowsRulesCallToFlowAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/call-to-flow-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Add automation call to flow action
     * Add automation call to flow action
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationFlowsRulesCallToFlowAction({
        flowUuid,
        ruleUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_CallFlowActionEditorRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/call-to-flow-action',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
}
