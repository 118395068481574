/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_ActionItem } from '../models/Miscellaneous_Models_ActionItem';
import type { Miscellaneous_Models_StateDto } from '../models/Miscellaneous_Models_StateDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CountriesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display states by country
     * Display states by country
     * @returns Miscellaneous_Models_StateDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousCountriesStates({
        countryId,
    }: {
        countryId: number,
    }): Observable<Array<Miscellaneous_Models_StateDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/countries/{countryId}/states',
            path: {
                'countryId': countryId,
            },
        });
    }
    /**
     * Display Countries
     * Display Countries
     * @returns Miscellaneous_Models_ActionItem Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousCountries(): Observable<Array<Miscellaneous_Models_ActionItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/countries',
        });
    }
}
