/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularHttpRequest } from './core/AngularHttpRequest';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { OpenAPI } from './core/OpenAPI';
import { CustomerContactsService } from './services/CustomerContactsService';
import { CustomerLocationsService } from './services/CustomerLocationsService';
import { CustomersService } from './services/CustomersService';
import { FeesService } from './services/FeesService';
import { InvoicesService } from './services/InvoicesService';
import { OrderService } from './services/OrderService';
import { VendorContactsService } from './services/VendorContactsService';
import { VendorInvoicesService } from './services/VendorInvoicesService';
import { VendorLocationsService } from './services/VendorLocationsService';
import { VendorsService } from './services/VendorsService';
@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: OpenAPI,
            useValue: {
                BASE: OpenAPI?.BASE ?? 'https://api.navix.io',
                VERSION: OpenAPI?.VERSION ?? '1.1',
                WITH_CREDENTIALS: OpenAPI?.WITH_CREDENTIALS ?? false,
                CREDENTIALS: OpenAPI?.CREDENTIALS ?? 'include',
                TOKEN: OpenAPI?.TOKEN,
                USERNAME: OpenAPI?.USERNAME,
                PASSWORD: OpenAPI?.PASSWORD,
                HEADERS: OpenAPI?.HEADERS,
                ENCODE_PATH: OpenAPI?.ENCODE_PATH,
            } as OpenAPIConfig,
        },
        {
            provide: BaseHttpRequest,
            useClass: AngularHttpRequest,
        },
        CustomerContactsService,
        CustomerLocationsService,
        CustomersService,
        FeesService,
        InvoicesService,
        OrderService,
        VendorContactsService,
        VendorInvoicesService,
        VendorLocationsService,
        VendorsService,
    ]
})
export class api {}

