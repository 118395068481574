/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Customer_Models_CustomerDto } from '../models/Customer_Models_CustomerDto';
import type { Customer_Models_CustomerListDto } from '../models/Customer_Models_CustomerListDto';
import type { Customer_Models_Requests_CustomerDetailsNewRequest } from '../models/Customer_Models_Requests_CustomerDetailsNewRequest';
import type { Customer_Models_Requests_CustomerRatingEngineInfoRequest } from '../models/Customer_Models_Requests_CustomerRatingEngineInfoRequest';
import type { Customer_Models_Requests_CustomerRequest } from '../models/Customer_Models_Requests_CustomerRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customers
     * Display customers
     * @returns Customer_Models_CustomerListDto Success
     * @throws ApiError
     */
    public getApiV11Customers(): Observable<Array<Customer_Models_CustomerListDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/customers',
        });
    }
    /**
     * Display customers
     * Display customers
     * @returns Customer_Models_CustomerListDto Success
     * @throws ApiError
     */
    public getApiV11CustomersList({
        odatacustomer,
    }: {
        odatacustomer?: Array<string>,
    }): Observable<Array<Customer_Models_CustomerListDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/customers/list',
            query: {
                'odatacustomer': odatacustomer,
            },
        });
    }
    /**
     * Display customer
     * Display customer
     * @returns Customer_Models_CustomerDto Success
     * @throws ApiError
     */
    public getApiV11CustomersDetail({
        customerId,
    }: {
        customerId: number,
    }): Observable<Customer_Models_CustomerDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/customers/{customerId}/detail',
            path: {
                'customerId': customerId,
            },
        });
    }
    /**
     * Display Customer Rating Engine Info
     * Display Customer Rating Engine Info
     * @returns string Success
     * @throws ApiError
     */
    public getApiV11CustomersRatingEngineInfo({
        customerId,
    }: {
        customerId: number,
    }): Observable<Record<string, string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/customers/{customerId}/rating-engine-info',
            path: {
                'customerId': customerId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Add customer
     * Add customer
     * @returns string Created
     * @throws ApiError
     */
    public postApiV11CustomersCustomers({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_CustomerRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/customers/customers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update customer details
     * Update customer details
     * @returns void
     * @throws ApiError
     */
    public putApiV11CustomersCustomersUpdateDetails({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_CustomerDetailsNewRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/customers/customers/update-details',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Customer Rating Engine Info
     * Update Customer Rating Engine Info
     * @returns void
     * @throws ApiError
     */
    public putApiV11CustomersCustomersRatingEngineInfo({
        customerId,
        requestBody,
    }: {
        customerId: number,
        requestBody?: Customer_Models_Requests_CustomerRatingEngineInfoRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.1/customers/customers/{customerId}/rating-engine-info',
            path: {
                'customerId': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
}
