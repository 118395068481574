/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_AddRoles_AddRoleRequestModel } from '../models/Identity_Application_AddRoles_AddRoleRequestModel';
import type { Identity_Application_UpdateRoles_UpdateRoleRequestModel } from '../models/Identity_Application_UpdateRoles_UpdateRoleRequestModel';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class RolesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display roles
     * Display roles
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthRoles(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/roles',
        });
    }
    /**
     * Add Role
     * Add Role in the system
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthRoles({
        requestBody,
    }: {
        requestBody?: Identity_Application_AddRoles_AddRoleRequestModel,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Update Role
     * Update Role in the system
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1AuthRoles({
        roleId,
        requestBody,
    }: {
        roleId: number,
        requestBody?: Identity_Application_UpdateRoles_UpdateRoleRequestModel,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/roles/{roleId}',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Remove Role
     * Remove Role in the system and idp
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1AuthRoles({
        roleId,
    }: {
        roleId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/auth/roles/{roleId}',
            path: {
                'roleId': roleId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
