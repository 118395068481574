/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_DuplicateProcessedRequest } from '../models/Invoice_Models_Requests_DuplicateProcessedRequest';
import type { Invoice_Models_Requests_VendorInvoiceStatusReasonRequest } from '../models/Invoice_Models_Requests_VendorInvoiceStatusReasonRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceStatusesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get invoice terminate reasons
     * Get invoice terminate reasons
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceStatusesTerminateReasons(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice-statuses/terminate-reasons',
        });
    }
    /**
     * Get invoice disputed reasons
     * Get invoice disputed reasons
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceStatusesDisputedReasons(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice-statuses/disputed-reasons',
        });
    }
    /**
     * Set invoice status to approved
     * Set invoice status to approved
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesSetStatusToApproved({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/set-status-to-approved',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Set invoice status to ready
     * Set invoice status to ready
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesSetStatusToReady({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/set-status-to-ready',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Set invoice status to terminate
     * Set invoice status to terminate
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesSetStatusToTerminate({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceStatusReasonRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/set-status-to-terminate',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set invoice status to disputed
     * Set invoice status to disputed
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesSetStatusToDisputed({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceStatusReasonRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/set-status-to-disputed',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set invoice status to duplicate processed
     * Set invoice status to duplicate processed
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesSetStatusToDuplicateProcessed({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_DuplicateProcessedRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/set-status-to-duplicate-processed',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set invoice status to audit
     * Set invoice status to audit
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesSetStatusToAudit({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceStatusReasonRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/set-status-to-audit',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Re audit vendor invoice
     * Re audit vendor invoice
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesReAudit({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/re-audit',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Document Subscription
     * Document Subscription
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesDocumentSubscription({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/document-subscription',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
}
