/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_UnmatchedDocumentSubmissionDto } from '../models/Invoice_Models_UnmatchedDocumentSubmissionDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class UnmatchedDocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get unmatched documents
     * Get unmatched documents
     * @returns Invoice_Models_UnmatchedDocumentSubmissionDto Success
     * @throws ApiError
     */
    public getApiV1UnmatchedDocument(): Observable<Array<Invoice_Models_UnmatchedDocumentSubmissionDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/unmatched-document',
        });
    }
    /**
     * Delete unmatched document
     * Delete unmatched document
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1UnmatchedDocument({
        submissionUuid,
    }: {
        submissionUuid?: string,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/unmatched-document',
            query: {
                'submissionUuid': submissionUuid,
            },
            errors: {
                304: `Not Modified`,
            },
        });
    }
    /**
     * Move unmatched document to awaiting
     * Move unmatched document to awaiting
     * @returns string Success
     * @throws ApiError
     */
    public patchApiV1UnmatchedDocument({
        submissionUuid,
    }: {
        submissionUuid?: string,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/unmatched-document',
            query: {
                'submissionUuid': submissionUuid,
            },
            errors: {
                304: `Not Modified`,
            },
        });
    }
}
