/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Miscellaneous_Models_TransportationModeDto } from '../models/Miscellaneous_Models_TransportationModeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TransportationModesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display Transportation modes
     * Display Transportation modes
     * @returns Miscellaneous_Models_TransportationModeDto Success
     * @throws ApiError
     */
    public getApiV1MiscellaneousTransportationModes(): Observable<Array<Miscellaneous_Models_TransportationModeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/miscellaneous/transportation-modes',
        });
    }
}
