/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Customer_Models_CustomerLocationTypeDto } from '../models/Customer_Models_CustomerLocationTypeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomerLocationTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer location types
     * Display customer location types
     * @returns Customer_Models_CustomerLocationTypeDto Success
     * @throws ApiError
     */
    public getApiV1CustomersCustomerLocationTypes(): Observable<Array<Customer_Models_CustomerLocationTypeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/customers/customer-location-types',
        });
    }
}
