/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_EmailTemplateDto } from '../models/Invoice_Models_EmailTemplateDto';
import type { Invoice_Models_EmailTemplateShortDto } from '../models/Invoice_Models_EmailTemplateShortDto';
import type { Invoice_Models_Requests_EmailNotificationRequest } from '../models/Invoice_Models_Requests_EmailNotificationRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display email templates
     * Display email templates
     * @returns Invoice_Models_EmailTemplateDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesNotificationsEmailTemplates({
        templateId,
    }: {
        templateId: number,
    }): Observable<Array<Invoice_Models_EmailTemplateDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/notifications/email-templates/{templateId}',
            path: {
                'templateId': templateId,
            },
        });
    }
    /**
     * Display email templates
     * Display email templates
     * @returns Invoice_Models_EmailTemplateShortDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesNotificationsEmailTemplates1(): Observable<Array<Invoice_Models_EmailTemplateShortDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/notifications/email-templates',
        });
    }
    /**
     * Add notification
     * Add notification
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesNotifications({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_EmailNotificationRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/notifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request was rejected`,
            },
        });
    }
}
