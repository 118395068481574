import {
  EnvironmentProviders,
  Provider,
  makeEnvironmentProviders
} from '@angular/core'

import { AngularHttpRequest } from './platform-v1/core/AngularHttpRequest'
import { platform_v1, platform_v1_1, platform_v1_2, platform_v2 } from '..'

export function provideApiCodegen(host: string): EnvironmentProviders {
  const value = {
    BASE: host
  }

  const openApiProviders: Array<Provider> = [
    platform_v1,
    platform_v1_1,
    platform_v1_2,
    platform_v2
  ].flatMap(provider => [
    {
      provide: provider.OpenAPI,
      useValue: value
    },
    {
      provide: provider.BaseHttpRequest,
      useClass: AngularHttpRequest
    }
  ])

  return makeEnvironmentProviders(openApiProviders)
}
