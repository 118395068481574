/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_Actions_ChangeReferenceNumberActionDto } from '../models/Navix_Automation_Models_Actions_ChangeReferenceNumberActionDto';
import type { Navix_Automation_Models_Requests_Actions_ChangeReferenceNumberActionEditorRequest } from '../models/Navix_Automation_Models_Requests_Actions_ChangeReferenceNumberActionEditorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationChangeReferenceNumberActionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display automation action for change refrence number
     * Display automation action for change refrence number
     * @returns Navix_Automation_Models_Actions_ChangeReferenceNumberActionDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRulesChangeReferenceNumberAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<Navix_Automation_Models_Actions_ChangeReferenceNumberActionDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/change-reference-number-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update change refrence number action
     * Update  change refrence action
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRulesChangeReferenceNumberAction({
        flowUuid,
        ruleUuid,
        actionUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_ChangeReferenceNumberActionEditorRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/change-reference-number-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Delete automation action for change refrence number
     * Delete automation action for change refrence number
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationFlowsRulesChangeReferenceNumberAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/change-reference-number-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Add automation action for change refrence number
     * Add automation action for change refrence number
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationFlowsRulesChangeReferenceNumberAction({
        flowUuid,
        ruleUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_ChangeReferenceNumberActionEditorRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/change-reference-number-action',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
}
