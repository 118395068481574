/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Vendor_Models_VendorLocationDto } from '../models/Vendor_Models_VendorLocationDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorLocationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor location by id
     * Display vendor location by id
     * @returns Vendor_Models_VendorLocationDto The request completed successfully
     * @throws ApiError
     */
    public getApiV1VendorsVendorLocations({
        vendorLocationId,
    }: {
        /**
         * The numeric ID for the location
         */
        vendorLocationId: number,
    }): Observable<Vendor_Models_VendorLocationDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/vendors/vendor-locations/{vendorLocationId}',
            path: {
                'vendorLocationId': vendorLocationId,
            },
            errors: {
                400: `Bad request`,
                403: `Authorization failed`,
                404: `The specified Vendor could not be found`,
            },
        });
    }
}
