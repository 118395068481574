/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_AutomationVariableDto } from '../models/Navix_Automation_Models_AutomationVariableDto';
import type { Navix_Automation_Models_AutomationVariableListDto } from '../models/Navix_Automation_Models_AutomationVariableListDto';
import type { Navix_Automation_Models_Requests_VariableAddRequest } from '../models/Navix_Automation_Models_Requests_VariableAddRequest';
import type { Navix_Automation_Models_Requests_VariableUpdateRequest } from '../models/Navix_Automation_Models_Requests_VariableUpdateRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationVariablesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display all automation variables per tenant
     * Display all automation variables per tenant
     * @returns Navix_Automation_Models_AutomationVariableListDto Success
     * @throws ApiError
     */
    public getApiV1AutomationVariables(): Observable<Array<Navix_Automation_Models_AutomationVariableListDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/variables',
        });
    }
    /**
     * Add automation variable
     * Add automation variable
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationVariables({
        requestBody,
    }: {
        requestBody?: Navix_Automation_Models_Requests_VariableAddRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/variables',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Display automation variable
     * Display automation variable
     * @returns Navix_Automation_Models_AutomationVariableDto Success
     * @throws ApiError
     */
    public getApiV1AutomationVariables1({
        variableUuid,
    }: {
        variableUuid: string,
    }): Observable<Navix_Automation_Models_AutomationVariableDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/variables/{variableUuid}',
            path: {
                'variableUuid': variableUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update automation variable
     * Update automation variable
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationVariables({
        variableUuid,
        requestBody,
    }: {
        variableUuid: string,
        requestBody?: Navix_Automation_Models_Requests_VariableUpdateRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/variables/{variableUuid}',
            path: {
                'variableUuid': variableUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Delete automation variable
     * Delete automation variable
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationVariables({
        variableUuid,
    }: {
        variableUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/variables/{variableUuid}',
            path: {
                'variableUuid': variableUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Retrieve all the rules per tenant using the given variable
     * Retrieve all the rules per tenant using the given variable
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AutomationVariablesInUse({
        variableUuid,
    }: {
        variableUuid: string,
    }): Observable<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/variables/{variableUuid}/in-use',
            path: {
                'variableUuid': variableUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
