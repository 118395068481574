/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_Models_Request_ReportCategoryRequest } from '../models/Identity_Application_Models_Request_ReportCategoryRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class ReportCategoriesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display report categories
     * Display report categories
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthReportCategories(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/report-categories',
        });
    }
    /**
     * Add Report Category
     * Add Report Category
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthReportCategories({
        requestBody,
    }: {
        requestBody?: Identity_Application_Models_Request_ReportCategoryRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/report-categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
