/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Sdk_Integration_Types_Security_Requests_InternalJwtRequest } from '../models/Navix_Sdk_Integration_Types_Security_Requests_InternalJwtRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Generate internal jwt access
     * Generate internal jwt access
     * @returns string Success
     * @throws ApiError
     */
    public postIntegrationV1ApiSecurityToken({
        requestBody,
    }: {
        requestBody?: Navix_Sdk_Integration_Types_Security_Requests_InternalJwtRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integration/v1/api/security/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
