/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_VendorInvoiceDto } from '../models/Invoice_Models_VendorInvoiceDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceTypesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice
     * Display vendor invoice
     * @returns Invoice_Models_VendorInvoiceDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceTypes(): Observable<Invoice_Models_VendorInvoiceDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice-types',
        });
    }
}
