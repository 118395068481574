/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Order_Models_OrderStopDto } from '../models/Order_Models_OrderStopDto';
import type { Order_Models_Requests_OrderStopRequest } from '../models/Order_Models_Requests_OrderStopRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrderStopsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display order stops
     * Display order stops
     * @returns Order_Models_OrderStopDto Success
     * @throws ApiError
     */
    public getApiV1OrdersStops({
        orderId,
    }: {
        orderId: number,
    }): Observable<Order_Models_OrderStopDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/{orderId}/stops',
            path: {
                'orderId': orderId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Add order stop item list
     * Add order stop item list
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1OrdersStopsAddRange({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: Array<Order_Models_Requests_OrderStopRequest>,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/orders/{orderId}/stops/add-range',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
