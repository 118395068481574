/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceAdjustedChargeRequest } from '../models/Invoice_Models_Requests_VendorInvoiceAdjustedChargeRequest';
import type { Invoice_Models_Requests_VendorInvoiceChargeAddBatchRequest } from '../models/Invoice_Models_Requests_VendorInvoiceChargeAddBatchRequest';
import type { Invoice_Models_Requests_VendorInvoiceChargeAddNewRequest } from '../models/Invoice_Models_Requests_VendorInvoiceChargeAddNewRequest';
import type { Invoice_Models_Requests_VendorInvoiceChargeAddSingleRequest } from '../models/Invoice_Models_Requests_VendorInvoiceChargeAddSingleRequest';
import type { Invoice_Models_Requests_VendorInvoiceChargeBatchNewRequest } from '../models/Invoice_Models_Requests_VendorInvoiceChargeBatchNewRequest';
import type { Invoice_Models_Requests_VendorInvoiceChargeBatchRequest } from '../models/Invoice_Models_Requests_VendorInvoiceChargeBatchRequest';
import type { Invoice_Models_Requests_VendorInvoiceChargeTypeRequest } from '../models/Invoice_Models_Requests_VendorInvoiceChargeTypeRequest';
import type { Invoice_Models_VarianceReasonDto } from '../models/Invoice_Models_VarianceReasonDto';
import type { Invoice_Models_VendorInvoiceChargeDto } from '../models/Invoice_Models_VendorInvoiceChargeDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice charges
     * Display vendor invoice charges
     * @returns Invoice_Models_VendorInvoiceChargeDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceCharges({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_VendorInvoiceChargeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice-charges',
            query: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Display vendor invoice charges variance reasons
     * Display vendor invoice charges variance reasons
     * @returns Invoice_Models_VarianceReasonDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceChargesVarianceReasons({
        chargeTypeId,
    }: {
        chargeTypeId?: number,
    }): Observable<Array<Invoice_Models_VarianceReasonDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/vendor-invoice-charges/variance-reasons',
            query: {
                'chargeTypeId': chargeTypeId,
            },
        });
    }
    /**
     * Update Vendor invoice charges adjusted and reason
     * Update Vendor invoice charges adjusted and reason
     * @returns string Success
     * @throws ApiError
     */
    public patchApiV1InvoicesVendorInvoiceCharges({
        vendorInvoiceChargeId,
        requestBody,
    }: {
        vendorInvoiceChargeId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceAdjustedChargeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/invoices/vendor-invoice-charges/{vendorInvoiceChargeId}',
            path: {
                'vendorInvoiceChargeId': vendorInvoiceChargeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Vendor invoice adjusted charges in batch
     * Update Vendor invoice adjusted charges in batch
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1InvoicesVendorInvoiceChargesBatch({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceChargeBatchRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-charges/batch',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add Vendor invoice charge
     * Add Vendor invoice charge
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesVendorInvoiceChargesBatch({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceChargeAddBatchRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-charges/batch',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add Vendor invoice charge
     * Add Vendor invoice charge
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesVendorInvoiceCharges({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceChargeAddSingleRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-charges',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Vendor invoice charge
     * Update Vendor invoice charge
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1InvoicesVendorInvoiceCharges({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceChargeTypeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-charges',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Add Vendor invoice charge batch
     * Add Vendor invoice charge batch
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesVendorInvoiceChargesBatchNew({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Array<Invoice_Models_Requests_VendorInvoiceChargeAddNewRequest>,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-charges/batch/new',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Vendor invoice charge batch
     * Update Vendor invoice charge batch
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1InvoicesVendorInvoiceChargesBatchNew({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Array<Invoice_Models_Requests_VendorInvoiceChargeBatchNewRequest>,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-charges/batch/new',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
