/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { GetUserResponse } from '../models/GetUserResponse';
import type { Identity_Application_Dto_LocalRole } from '../models/Identity_Application_Dto_LocalRole';
import type { Identity_Application_GetUserByGuid_GetUserByGuidResponseModel } from '../models/Identity_Application_GetUserByGuid_GetUserByGuidResponseModel';
import type { Identity_Application_GetUserTenantsByUser_TenantUserResponse } from '../models/Identity_Application_GetUserTenantsByUser_TenantUserResponse';
import type { Identity_Application_Models_UserDto } from '../models/Identity_Application_Models_UserDto';
import type { Identity_Application_UpdateCurrentUser_UpdateCurrentUserResponseModel } from '../models/Identity_Application_UpdateCurrentUser_UpdateCurrentUserResponseModel';
import type { Identity_Application_UpdateUser_UpdateUserResponseModel } from '../models/Identity_Application_UpdateUser_UpdateUserResponseModel';
import type { Identity_Domain_Menu } from '../models/Identity_Domain_Menu';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display tenants to which a user belongs
     * Display tenants to which a user belongs
     * @returns Identity_Application_GetUserTenantsByUser_TenantUserResponse Success
     * @throws ApiError
     */
    public getApiV1AuthUsersTenants(): Observable<Array<Identity_Application_GetUserTenantsByUser_TenantUserResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/users/tenants',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Display menu options for current user
     * Display menu options for current user
     * @returns Identity_Domain_Menu Success
     * @throws ApiError
     */
    public getApiV1AuthMenuCurrent(): Observable<Identity_Domain_Menu> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/menu/current',
        });
    }
    /**
     * Display tenants to which a user belongs
     * Display tenants to which a user belongs
     * @returns string Success
     * @throws ApiError
     */
    public patchApiV1AuthUsersTenantsCurrent({
        tenantId,
    }: {
        tenantId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/auth/users/tenants/{tenantId}/current',
            path: {
                'tenantId': tenantId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Create new user
     * Create new user
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1UsersUsers({
        requestBody,
    }: {
        requestBody?: any,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/users/users',
            body: requestBody,
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Display User
     * Display user
     * @returns GetUserResponse Success
     * @throws ApiError
     */
    public getApiV1AuthUsersCurrent(): Observable<GetUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/users/current',
        });
    }
    /**
     * Update user
     * Update user
     * @returns Identity_Application_UpdateCurrentUser_UpdateCurrentUserResponseModel Success
     * @throws ApiError
     */
    public putApiV1AuthUsersCurrent({
        requestBody,
    }: {
        requestBody?: any,
    }): Observable<Identity_Application_UpdateCurrentUser_UpdateCurrentUserResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/users/current',
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display User
     * Display user
     * @returns Identity_Application_GetUserByGuid_GetUserByGuidResponseModel Success
     * @throws ApiError
     */
    public getApiV1UsersDetails({
        userGuid,
    }: {
        userGuid: string,
    }): Observable<Identity_Application_GetUserByGuid_GetUserByGuidResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/{userGuid}/details',
            path: {
                'userGuid': userGuid,
            },
        });
    }
    /**
     * Display Users
     * Display users
     * @returns Identity_Application_Models_UserDto Success
     * @throws ApiError
     */
    public getApiV1UsersList(): Observable<Array<Identity_Application_Models_UserDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users-list',
        });
    }
    /**
     * Update user
     * Update user
     * @returns Identity_Application_UpdateUser_UpdateUserResponseModel Success
     * @throws ApiError
     */
    public putApiV1AuthUsers({
        requestBody,
    }: {
        requestBody?: any,
    }): Observable<Identity_Application_UpdateUser_UpdateUserResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/users',
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Display user roles
     * Display user roles
     * @returns Identity_Application_Dto_LocalRole Success
     * @throws ApiError
     */
    public getApiV1AuthUsersRoles(): Observable<Array<Identity_Application_Dto_LocalRole>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/users/roles',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Add user roles
     * Add user roles
     * @returns string Success
     * @throws ApiError
     */
    public patchApiV1AuthUsersRoles({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody?: Array<number>,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/auth/users/{userId}/roles',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Reset user password
     * Reset user password
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthUsersResetPassword({
        userGuid,
    }: {
        userGuid: string,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/users/{userGuid}/reset-password',
            path: {
                'userGuid': userGuid,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Update user with new api access
     * Update user with new api access
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1AuthUsersGenerateNewApiKeyAccess({
        userUuid,
    }: {
        userUuid: string,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/{userUuid}/users/generate-new-api-key-access',
            path: {
                'userUuid': userUuid,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
