/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_ExceptionCategoryDto } from '../models/Invoice_Models_ExceptionCategoryDto';
import type { Invoice_Models_VendorinvoiceExceptionsDisplay } from '../models/Invoice_Models_VendorinvoiceExceptionsDisplay';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceExceptionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice exceptions
     * Display vendor invoice exceptions
     * @returns Invoice_Models_VendorinvoiceExceptionsDisplay Success
     * @throws ApiError
     */
    public getApiV1InvoicesExceptions({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_VendorinvoiceExceptionsDisplay>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/exceptions/{vendorInvoiceId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Run Logic to find exceptions and add it in db
     * Run Logic to find exceptions and add it in db
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1InvoicesExceptions({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/exceptions/{vendorInvoiceId}',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Display vendor invoice exceptions categories
     * Display vendor invoice exceptions categories
     * @returns Invoice_Models_ExceptionCategoryDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesExceptionsCategories(): Observable<Array<Invoice_Models_ExceptionCategoryDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/exceptions/categories',
        });
    }
}
