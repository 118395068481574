/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class InvoicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns vendor invoice details
     *
     * @returns any Success
     * @throws ApiError
     */
    public getApiV12InvoicesDetail({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.2/invoices/{vendorInvoiceId}/detail',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
}
