/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_Actions_RaiseExceptionActionDto } from '../models/Navix_Automation_Models_Actions_RaiseExceptionActionDto';
import type { Navix_Automation_Models_Requests_Actions_RaiseExceptionActionEditorRequest } from '../models/Navix_Automation_Models_Requests_Actions_RaiseExceptionActionEditorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AutomationRaiseExceptionActionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display raise exception
     * Display raise exception
     * @returns Navix_Automation_Models_Actions_RaiseExceptionActionDto Success
     * @throws ApiError
     */
    public getApiV1AutomationFlowsRulesRaiseExceptionAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<Navix_Automation_Models_Actions_RaiseExceptionActionDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/raise-exception-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Update raise exception
     * Update raise exception
     * @returns void
     * @throws ApiError
     */
    public putApiV1AutomationFlowsRulesRaiseExceptionAction({
        flowUuid,
        ruleUuid,
        actionUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_RaiseExceptionActionEditorRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/raise-exception-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
    /**
     * Delete raise exception
     * Delete automation action
     * @returns void
     * @throws ApiError
     */
    public deleteApiV1AutomationFlowsRulesRaiseExceptionAction({
        flowUuid,
        ruleUuid,
        actionUuid,
    }: {
        flowUuid: string,
        ruleUuid: string,
        actionUuid: string,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/raise-exception-action/{actionUuid}',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
                'actionUuid': actionUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Add araise exception
     * Add raise exceptione
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1AutomationFlowsRulesRaiseExceptionAction({
        flowUuid,
        ruleUuid,
        requestBody,
    }: {
        flowUuid: string,
        ruleUuid: string,
        requestBody?: Navix_Automation_Models_Requests_Actions_RaiseExceptionActionEditorRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/automation/flows/{flowUuid}/rules/{ruleUuid}/raise-exception-action',
            path: {
                'flowUuid': flowUuid,
                'ruleUuid': ruleUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
            },
        });
    }
}
