/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceUpdateCustomerRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateCustomerRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateVendorRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateVendorRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class InvoicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns the dashboard invoice list
     * Returns the dashboard invoice list
     * @returns any Success
     * @throws ApiError
     */
    public getApiV11InvoicesDashboard({
        options,
    }: {
        options?: any,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/invoices/dashboard',
            query: {
                'options': options,
            },
        });
    }
    /**
     * Returns vendor invoice details
     * Returns vendor invoice details
     * @returns any Success
     * @throws ApiError
     */
    public getApiV11InvoicesDetail({
        vendorInvoiceId,
        odatainvoice,
        odatavendor,
        odatacustomer,
    }: {
        vendorInvoiceId: number,
        odatainvoice?: Array<string>,
        odatavendor?: Array<string>,
        odatacustomer?: Array<string>,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.1/invoices/{vendorInvoiceId}/detail',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            query: {
                'odatainvoice': odatainvoice,
                'odatavendor': odatavendor,
                'odatacustomer': odatacustomer,
            },
        });
    }
    /**
     * Update invoice's vendor v1.1
     * Update invoice's vendor
     * @returns any Success
     * @throws ApiError
     */
    public postApiV11InvoicesUpdateVendor({
        requestBody,
    }: {
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateVendorRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.1/invoices/update-vendor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update invoice's customer
     * Update invoice's customer
     * @returns any Success
     * @throws ApiError
     */
    public patchApiV11InvoicesCustomer({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateCustomerRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1.1/invoices/{vendorInvoiceId}/customer',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
}
