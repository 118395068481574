/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_OrderChargeDto } from '../models/Invoice_Models_OrderChargeDto';
import type { Order_Models_OrderChargeDto } from '../models/Order_Models_OrderChargeDto';
import type { Order_Models_OrderChargeDtoNew } from '../models/Order_Models_OrderChargeDtoNew';
import type { Order_Models_Requests_OrderChargeRequest } from '../models/Order_Models_Requests_OrderChargeRequest';
import type { Order_Models_Requests_OrderChargeTypeRequest } from '../models/Order_Models_Requests_OrderChargeTypeRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrderChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display order charges
     * Display order charges
     * @returns Invoice_Models_OrderChargeDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesOrderCharges({
        orderId,
        vendorId,
    }: {
        orderId: number,
        vendorId?: number,
    }): Observable<Array<Invoice_Models_OrderChargeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/order-charges',
            query: {
                'orderId': orderId,
                'vendorId': vendorId,
            },
        });
    }
    /**
     * Display order charges
     * Display order charges
     * @returns Order_Models_OrderChargeDto Success
     * @throws ApiError
     */
    public getApiV1OrdersCharges({
        orderId,
    }: {
        orderId: number,
    }): Observable<Array<Order_Models_OrderChargeDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/{orderId}/charges',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Update order charge
     * Update order charge
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1OrdersCharges({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: Order_Models_Requests_OrderChargeTypeRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/orders/{orderId}/charges',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Display order charges
     * Display order charges
     * @returns Order_Models_OrderChargeDtoNew Success
     * @throws ApiError
     */
    public getApiV1OrdersChargesNew({
        orderId,
    }: {
        orderId: number,
    }): Observable<Array<Order_Models_OrderChargeDtoNew>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/orders/{orderId}/charges/new',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Add order charge list
     * Add order charge list
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1OrdersChargesAddRange({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: Array<Order_Models_Requests_OrderChargeRequest>,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/orders/{orderId}/charges/add-range',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
