/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_Models_Request_AddTenantContactRequest } from '../models/Identity_Application_Models_Request_AddTenantContactRequest';
import type { Identity_Application_Models_Request_UpdateTenantContactRequest } from '../models/Identity_Application_Models_Request_UpdateTenantContactRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class TenantContactsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add Tenant Contacts
     * Add tenant contacts
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthTenantsContacts({
        tenantGuid,
        requestBody,
    }: {
        tenantGuid: string,
        requestBody?: Identity_Application_Models_Request_AddTenantContactRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/tenants/{tenantGuid}/contacts',
            path: {
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Edit Tenant Contact
     * Edit tenant contacts
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1AuthTenantsContacts({
        tenantContactGuid,
        tenantGuid,
        requestBody,
    }: {
        tenantContactGuid: string,
        tenantGuid: string,
        requestBody?: Identity_Application_Models_Request_UpdateTenantContactRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/auth/tenants/{tenantGuid}/contacts/{tenantContactGuid}',
            path: {
                'tenantContactGuid': tenantContactGuid,
                'tenantGuid': tenantGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Soft delete tenant contact
     * Soft delete tenant contact
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1AuthTenantsContacts({
        tenantGuid,
        tenantContactGuid,
    }: {
        tenantGuid: string,
        tenantContactGuid: string,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/auth/tenants/{tenantGuid}/contacts/{tenantContactGuid}',
            path: {
                'tenantGuid': tenantGuid,
                'tenantContactGuid': tenantContactGuid,
            },
        });
    }
}
