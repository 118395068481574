/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Automation_Models_ValidationResultDto } from '../models/Navix_Automation_Models_ValidationResultDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Allows you to validate a condition expressed as a string.
     * Returns true if the condition is valid and has the correct syntax; otherwise, it returns false and the error message.
     * @returns Navix_Automation_Models_ValidationResultDto Success
     * @throws ApiError
     */
    public getApiV1AutomationValidationRulesCondition({
        condition,
    }: {
        condition?: string,
    }): Observable<Navix_Automation_Models_ValidationResultDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/automation/validation/rules/condition',
            query: {
                'condition': condition,
            },
        });
    }
}
