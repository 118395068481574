/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Models_Requests_VendorInvoiceAddNoteRequest } from '../models/Invoice_Models_Requests_VendorInvoiceAddNoteRequest';
import type { Invoice_Models_Requests_VendorInvoiceUpdateNoteRequest } from '../models/Invoice_Models_Requests_VendorInvoiceUpdateNoteRequest';
import type { Invoice_Models_VendorInvoiceNoteDto } from '../models/Invoice_Models_VendorInvoiceNoteDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class VendorInvoiceNotesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display vendor invoice notes
     * Display vendor invoice notes
     * @returns Invoice_Models_VendorInvoiceNoteDto Success
     * @throws ApiError
     */
    public getApiV1InvoicesVendorInvoiceNotes({
        vendorInvoiceId,
    }: {
        vendorInvoiceId: number,
    }): Observable<Array<Invoice_Models_VendorInvoiceNoteDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/invoices/{vendorInvoiceId}/vendor-invoice-notes',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
        });
    }
    /**
     * Create vendor invoice note/dispute
     * Create vendor invoice note/dispute
     * @returns any Created
     * @throws ApiError
     */
    public postApiV1InvoicesNote({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceAddNoteRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/invoices/{vendorInvoiceId}/note',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update Vendor invoice note/dispute
     * Update Vendor invoice note/dispute
     * @returns any Success
     * @throws ApiError
     */
    public putApiV1InvoicesNote({
        vendorInvoiceId,
        requestBody,
    }: {
        vendorInvoiceId: number,
        requestBody?: Invoice_Models_Requests_VendorInvoiceUpdateNoteRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/invoices/{vendorInvoiceId}/note',
            path: {
                'vendorInvoiceId': vendorInvoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Marking Vendor invoice note as completed
     * Marking Vendor invoice note as completed
     * @returns string Success
     * @throws ApiError
     */
    public patchApiV1InvoicesNotesMarkAsComplete({
        vendorInvoiceNoteId,
    }: {
        vendorInvoiceNoteId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/invoices/{vendorInvoiceNoteId}/notes-mark-as-complete',
            path: {
                'vendorInvoiceNoteId': vendorInvoiceNoteId,
            },
        });
    }
    /**
     * Delete vendor invoice note
     * Delete vendor invoice note
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1Invoices({
        vendorInvoiceNoteId,
    }: {
        vendorInvoiceNoteId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/invoices/{vendorInvoiceNoteId}',
            path: {
                'vendorInvoiceNoteId': vendorInvoiceNoteId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
