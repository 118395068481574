/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Sdk_Integration_Types_Audit_AuditPayload } from '../models/Navix_Sdk_Integration_Types_Audit_AuditPayload';
import type { Navix_Services_Sdk_Audit } from '../models/Navix_Services_Sdk_Audit';
import type { Navix_Services_Sdk_AuditDTO } from '../models/Navix_Services_Sdk_AuditDTO';
import type { Navix_Services_Sdk_CreateNoteRequest } from '../models/Navix_Services_Sdk_CreateNoteRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AuditService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display an audit object
     * Display an audit object
     * @returns Navix_Services_Sdk_Audit Success
     * @throws ApiError
     */
    public getApiVAudits({
        auditUuid,
        version,
    }: {
        auditUuid: string,
        version: string,
    }): Observable<Navix_Services_Sdk_Audit> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v{version}/audits/{auditUuid}',
            path: {
                'auditUuid': auditUuid,
                'version': version,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Gets an audit object by its order UUID
     * Gets an audit object by its order UUID
     * @returns Navix_Services_Sdk_Audit Success
     * @throws ApiError
     */
    public getApiVAuditsOrder({
        orderUuid,
        version,
    }: {
        orderUuid: string,
        version: string,
    }): Observable<Navix_Services_Sdk_Audit> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v{version}/audits/order/{orderUuid}',
            path: {
                'orderUuid': orderUuid,
                'version': version,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Put an audit object using an order Uuid
     * Put an audit object using an order Uuid
     * @returns Navix_Services_Sdk_Audit Success
     * @throws ApiError
     */
    public putApiVAuditsOrder({
        orderUuid,
        version,
        requestBody,
    }: {
        orderUuid: string,
        version: string,
        requestBody?: Navix_Services_Sdk_AuditDTO,
    }): Observable<Navix_Services_Sdk_Audit> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v{version}/audits/order/{orderUuid}',
            path: {
                'orderUuid': orderUuid,
                'version': version,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display an audit object
     * Display an audit object
     * @returns Navix_Services_Sdk_Audit Success
     * @throws ApiError
     */
    public postApiVAuditsNotes({
        auditUuid,
        version,
        requestBody,
    }: {
        auditUuid: string,
        version: string,
        requestBody?: Navix_Services_Sdk_CreateNoteRequest,
    }): Observable<Array<Navix_Services_Sdk_Audit>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v{version}/audits/{auditUuid}/notes',
            path: {
                'auditUuid': auditUuid,
                'version': version,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Display Disputes
     * Display order
     * @returns Navix_Sdk_Integration_Types_Audit_AuditPayload Success
     * @throws ApiError
     */
    public getApiV2OrdersAudit({
        orderId,
    }: {
        orderId: string,
    }): Observable<Navix_Sdk_Integration_Types_Audit_AuditPayload> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/orders/audit/{orderId}',
            path: {
                'orderId': orderId,
            },
        });
    }
}
