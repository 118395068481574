/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Customer_Models_CustomerContactDto } from '../models/Customer_Models_CustomerContactDto';
import type { Customer_Models_Requests_AddCustomerContactRequest } from '../models/Customer_Models_Requests_AddCustomerContactRequest';
import type { Customer_Models_Requests_UpdateCustomerContactRequest } from '../models/Customer_Models_Requests_UpdateCustomerContactRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class CustomerContactsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Display customer contacts
     * Display customer contacts
     * @returns Customer_Models_CustomerContactDto Success
     * @throws ApiError
     */
    public getApiV1CustomersCustomerContacts({
        customerId,
    }: {
        customerId: number,
    }): Observable<Array<Customer_Models_CustomerContactDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/customers/{customerId}/customer-contacts',
            path: {
                'customerId': customerId,
            },
        });
    }
    /**
     * @deprecated
     * Add customer contact
     * Add customer contact
     * @returns string Created
     * @throws ApiError
     */
    public postApiV1CustomersCustomerContacts({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_AddCustomerContactRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/customers/customer-contacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @deprecated
     * Update customer contact
     * Update customer contact
     * @returns string Success
     * @throws ApiError
     */
    public putApiV1CustomersCustomerContacts({
        requestBody,
    }: {
        requestBody?: Customer_Models_Requests_UpdateCustomerContactRequest,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/customers/customer-contacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @deprecated
     * Soft delete customer contact
     * Soft delete customer contact
     * @returns string Success
     * @throws ApiError
     */
    public deleteApiV1CustomersDelete({
        customerContactId,
    }: {
        customerContactId: number,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/customers/{customerContactId}/delete',
            path: {
                'customerContactId': customerContactId,
            },
        });
    }
}
