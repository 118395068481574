/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Identity_Application_AddPermissions_AddPermissionRequestModel } from '../models/Identity_Application_AddPermissions_AddPermissionRequestModel';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add Permission
     * Add Permission in the system and idp
     * @returns string Success
     * @throws ApiError
     */
    public postApiV1AuthPermissions({
        requestBody,
    }: {
        requestBody?: Identity_Application_AddPermissions_AddPermissionRequestModel,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/auth/permissions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Get all Permissions
     * Get all Permissions
     * @returns string Success
     * @throws ApiError
     */
    public getApiV1AuthPermissions(): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/auth/permissions',
        });
    }
}
